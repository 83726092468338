import { Button, ModalFooter } from "reactstrap";
import { useViewEditTenderStore } from "../../../../../../Stores/viewEditTender";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useAuth } from "../../../../../../Services/auth";
import { useVademecumStore } from "../../../../../../Stores/vademecumStore";
import { returnTenderTitle } from "../summaryResultModal";
import XLSXDownloadButton from "../../XLSX/XLSXDownloadButton";

function downloadPDF(instance) {
  const summaryNameToGET = useViewEditTenderStore.getState().summaryNameToGET;
  // var file = new File(
  //   [instance.blob],
  //   `${summaryNameToGET.replace(/[ &/\\#,+()$~%.'":*?<>{}]/g, "-")}.pdf`
  // );

  // const href = window.URL.createObjectURL(instance.blob);
  const link = document.createElement("a");
  link.href = instance.url;
  link.setAttribute("download", `${summaryNameToGET}.pdf`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  // window.URL.revokeObjectURL(href);
}

export default function SummaryModalFooter({ adminSwitch, setAdminSwitch }) {
  const { t, i18n } = useTranslation();
  const auth = useAuth();
  const [setSummaryResultModalVisible, summary, summaryStatus, summaryFieldValuesStatus, tenderStatus, tender] =
    useViewEditTenderStore((state) => [
      state.setSummaryResultModalVisible,
      state.summary,
      state.summaryStatus,
      state.summaryFieldValuesStatus,
      state.tenderStatus,
      state.tender,
    ]);
  const [setEmailUserModalVisible, instance, updateInstance] = useVademecumStore((state) => [
    state.setEmailUserModalVisible,
    state.instance,
    state.updateInstance,
  ]);

  return (
    <ModalFooter className="d-flex justify-content-between flex-column flex-sm-row justify-content-between">
      <Button
        className="tc-full-width-small"
        onClick={() => {
          setSummaryResultModalVisible(false);
        }}
        type="button"
        color="danger"
      >
        {t("Close")}
      </Button>
      {adminSwitch && (summaryStatus === "loaded" || summaryStatus === "saving") ? (
        <Link className="tc-full-width-small" to={`/admin/view_tender/${summary.tender}`} target="_blank">
          <Button className="tc-full-width-small" type="button" color="secondary">
            {t("Edit Tender")}
          </Button>
        </Link>
      ) : null}
      {auth.user.is_superuser ? (
        <Button
          className={`me-auto tc-full-width-small ${tenderStatus === "loaded" ? "" : "disabled"}`}
          onClick={() => {
            setEmailUserModalVisible(true);
          }}
          type="button"
          color="secondary"
        >
          {t("Email User")}
        </Button>
      ) : null}
      {summaryFieldValuesStatus === "saving" || summaryStatus === "saving" ? (
        <div className="me-auto ms-auto">{t("Saving changes...")}</div>
      ) : null}
      {summaryStatus === "loaded" || summaryStatus === "saving" ? (
        <div className="d-flex tc-flex-gap-10px flex-column flex-sm-row tc-full-width-small">
          {auth.user.email ? (
            <Button
              className="tc-full-width-small"
              type="button"
              color="secondary"
              onClick={() => {
                updateInstance();
                downloadPDF(instance);
              }}
            >
              {t("Download PDF")}
            </Button>
          ) : null}

          <XLSXDownloadButton tenderTitle={returnTenderTitle(tender, i18n.language.slice(0, 2))} />
          {auth.user.is_superuser ? (
            <Button
              className="tc-full-width-small"
              onClick={() => {
                setAdminSwitch(!adminSwitch);
              }}
              type="button"
              color="secondary"
            >
              {adminSwitch ? t("Switch to View") : t("Switch to Edit")}
            </Button>
          ) : null}
        </div>
      ) : null}
    </ModalFooter>
  );
}
