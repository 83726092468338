import React from "react";
import appalti_logo from "../../../../../Assets/Images/appalti_logo.png";
import summary_pdf_shapes from "../../../../../Assets/Images/summary_pdf_shapes.png";
import tenders_club_tagline from "../../../../../Assets/Images/tenders_club_tagline.png";
import tenders_club_logo_only from "../../../../../Assets/Images/tenders_club_logo_only.png";
// import dayjs from "dayjs";
import {
  Document,
  Page,
  Text,
  StyleSheet,
  Image,
  Font,
  View,
  Link,
} from "@react-pdf/renderer";

import { useTranslation } from "react-i18next";
import ItemsTable from "./ItemsTable";

Font.register({
  family: "Oswald",
  src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
});

Font.register({
  family: "Montserrat",
  fonts: [
    {
      src: "https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Ew-.ttf",
    },
    {
      src: "https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCu170w-.ttf",
      fontWeight: 600,
    },
  ],
});

const RenderTable = ({ item }) => {
  const { i18n } = useTranslation();

  const split = item.value.split("\n");
  const split1 = split[0].split("|");

  if (split.length > 1 && split1) {
    const split2 = split[1].split("|");
    if (split1.length === split2.length) {
      return (
        <View wrap={false} style={styles.tableView}>
          <Text style={styles.itemHeading}>
            {item.field.translations[i18n.language.slice(0, 2)]
              ? item.field.translations[i18n.language.slice(0, 2)].display_name
              : item.field.translations[Object.keys(item.field.translations)[0]]
                  .display_name}
          </Text>
          <ItemsTable rows={split} transposed={false} />
        </View>
      );
    }
  }

  return <View wrap={false} style={styles.tableView}></View>;
};

export const SummaryPDFTableStyle = ({
  tenderTitle,
  textValues,
  reorderedValues,
}) => {
  const { i18n } = useTranslation();

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {/* <View style={[styles.doubleColumnView, styles.justifyBetween]}>
          <Text style={styles.summaryNameAtTop} fixed>{`${t(
            "Summary"
          )}: ${summaryNameToGET}`}</Text>
          <Text style={styles.dateAtTop} fixed>
            {dayjs().format("DD/MM/YYYY")}
          </Text>
        </View> */}
        <View style={styles.Header} fixed>
          <Image style={styles.headerShapes} src={summary_pdf_shapes} />
          <View style={styles.headerLogoFlex}>
            <Image style={styles.logoTagline} src={tenders_club_tagline} />
            <Image style={styles.logoLogo} src={tenders_club_logo_only} />
          </View>
        </View>
        <Text style={styles.title}>{tenderTitle}</Text>
        {/* <Text style={styles.author}>{`${t("Generated for")} ${email}`}</Text> */}
        {reorderedValues.map((item, idx) => {
          return (
            <React.Fragment key={`${idx}`}>
              {item.return_type === "TEXT" ? (
                <View
                  wrap={true}
                  style={[
                    styles.row,
                    idx === textValues.length - 1 ? styles.lastRow : null,
                  ]}
                >
                  <View style={[styles.FullHeightCell, { width: "25%" }]}>
                    <Text style={[{ marginTop: 16 }]}>
                      {item.field.translations[i18n.language.slice(0, 2)]
                        ? item.field.translations[i18n.language.slice(0, 2)]
                            .display_name
                        : item.field.translations[
                            Object.keys(item.field.translations)[0]
                          ].display_name}
                    </Text>
                  </View>
                  <Text
                    style={[styles.cell, styles.secondCell, { width: "75%" }]}
                  >
                    {item.value}
                  </Text>
                </View>
              ) : (
                // <View></View>
                <RenderTable item={item} />
              )}
            </React.Fragment>
          );
        })}
        <View style={styles.footerContainer} fixed>
          <Text style={styles.horizontalLine}></Text>
          <View style={styles.footerTextContent}>
            <Image style={styles.ufficioImage} src={appalti_logo} />
            <Text>Ufficio Appalti Srl</Text>
            <Text>Milano. +39 02 92853681</Text>
            <View style={styles.FlexColumnFooter}>
              <Link style={styles.link} src="mailto:info@tenders.club">
                info@tenders.club
              </Link>
              <Link style={styles.link} src="mailto:info@ufficioappalti.com">
                info@ufficioappalti.com
              </Link>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export const MemoizedPDFTableStyle = React.memo(SummaryPDFTableStyle);

export const removeDoubleQuotes = (value) => {
  if (value.startsWith('"') && value.endsWith('"')) {
    return value.substring(1, value.length - 1);
  }

  return value;
};

const styles = StyleSheet.create({
  Header: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 30,
    height: "60pt",
  },
  headerShapes: { height: "120%", width: "auto" },
  headerLogoFlex: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginLeft: "auto",
  },
  logoTagline: { height: "25%", width: "auto", marginRight: 5, marginTop: 20 },
  logoLogo: { height: "90%", width: "auto" },
  tableView: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  page: {
    position: "relative",
    height: "100%",
    width: "100%",
    fontSize: 11,
    paddingTop: 40,
    paddingBottom: 120,
    paddingHorizontal: 35,
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    alignItems: "center",
  },
  link: {
    fontSize: 8,
    color: "#726ce3",
  },
  FlexColumnFooter: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  ufficioImage: {
    height: "auto",
    width: "20pt",
  },
  footerContainer: {
    position: "absolute",
    bottom: 30,
    left: 35,
    fontFamily: "Montserrat",
    height: "60pt",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  horizontalLine: {
    width: "50%",
    borderTop: "1pt solid #726ce3",
  },
  footerTextContent: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  headerImage: {
    position: "absolute",
    top: 30,
    left: 26,
    height: "auto",
    width: "101%",
  },
  summaryNameAtTop: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "left",
    color: "grey",
    fontFamily: "Montserrat",
  },
  // extraMargin: {
  //   marginTop: 16,
  //   marginBottom: 32,
  // },
  title: {
    width: "75%",
    fontSize: 24,
    textAlign: "center",
    fontFamily: "Oswald",
    marginBottom: 60,
  },
  itemHeading: {
    fontSize: 16,
    marginLeft: 12,
    marginTop: 16,
    marginBottom: 2,
    fontFamily: "Oswald",
  },
  FullHeightCell: {
    display: "flex",
    borderRight: "1pt solid #212529",
  },
  cell: {
    lineHeight: 1.3,
    paddingHorizontal: 5,
    paddingVertical: 10,
    display: "flex",
    marginTop: "auto",
    marginBottom: "auto",
  },
  firstCell: {
    fontWeight: 600,
    fontSize: 12,
    textAlign: "right",
    fontFamily: "Oswald",
  },
  secondCell: {
    fontSize: 11,
    textAlign: "left",
    fontFamily: "Montserrat",
    borderRight: "0pt solid #212529",
  },
  row: {
    height: "auto",
    minHeight: 50,
    width: "75%",
    fontSize: 10,
    borderBottom: "1pt solid #212529",
    flexDirection: "row",
    display: "flex",
    justifyContent: "center",
  },
  lastRow: {
    borderBottom: "0pt solid #212529",
  },
});
