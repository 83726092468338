import { createWithEqualityFn } from "zustand/traditional";
import { shallow } from "zustand/shallow";
// import { immer } from "zustand/middleware/immer";
import { persist, createJSONStorage } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import { mergeDeepLeft } from "ramda";
// import devDebug from "../Helpers/debug";
import all_cpv_trees from "../Assets/CPVs/all_cpv_trees.json";
import { get, set, del } from "idb-keyval"; // can use anything: IndexedDB, Ionic Storage, etc.
import { useTenderStrategyStore } from "./tenderStrategyStore";
import i18n from "../i18n";

const storage = {
  getItem: async (name) => {
    //devDebug(name, "has been retrieved");
    return (await get(name)) || null;
  },
  setItem: async (name, value) => {
    //devDebug(name, "with value", value, "has been saved");
    await set(name, value);
  },
  removeItem: async (name) => {
    //devDebug(name, "has been deleted");
    await del(name);
  },
};

// define the initial state
const initialState = {
  _hasHydrated: false,
  reloadCPVs: false,
  cpvTrees: {
    tender_strategy: { default: all_cpv_trees[i18n.language.slice(0, 2)] },
    edit_user: { default: all_cpv_trees[i18n.language.slice(0, 2)] },
  },
  refreshCPVTree: {
    tender_strategy: { default: true },
    edit_user: { default: true },
  },
  CPVStage: "initial",
};

export const useCPVStore = createWithEqualityFn(
  persist(
    immer((set, get) => ({
      ...initialState,
      setHasHydrated: (state) => {
        set({
          _hasHydrated: state,
        });
      },

      // setSomething: (payload) =>
      //   set((state) => {
      //     state.reloadCPVs = payload;
      //   }),
      setReloadCPVS: (payload) =>
        set((state) => {
          state.reloadCPVs = payload;
        }),
      setTree: (newCPVTree, section, id) =>
        set((state) => {
          state.cpvTrees[section][id] = newCPVTree;
        }),
      verifyCPVDataExists: () =>
        set((state) => {
          const allStrategies = {
            ...useTenderStrategyStore.getState().editableStrategies
              .tender_strategy,
            ...useTenderStrategyStore.getState().editableStrategies.edit_user,
          };

          let keys = {};

          for (let section of Object.keys(state.cpvTrees)) {
            for (let id of Object.keys(allStrategies)) {
              if (!Object.keys(state.cpvTrees[section]).includes(id)) {
                state.cpvTrees[section][id] = all_cpv_trees[i18n.language.slice(0, 2)];
                state.refreshCPVTree[section][id] = true;
              }
            }
          }
          for (let section of Object.keys(state.cpvTrees)) {
            for (let id of Object.keys(state.cpvTrees[section])) {
              if (!Object.keys(allStrategies).includes(id)) {
                if (!keys[section]) {
                  keys[section] = {};
                }
                if (id !== "default" && id !== "all" && id !== "anonymous")
                  keys[section][id] = true;
              }
            }
          }
          for (let section of Object.keys(keys)) {
            for (let key of Object.keys(keys[section])) {
              delete state.cpvTrees[section][key];
              delete state.refreshCPVTree[section][key];
            }
          }
          // devDebug(
          //   `verifyCPVDataExists -> These keys don't exist anymore and should be deleted from mapData and mapState: ${JSON.stringify(
          //     keys
          //   )}`
          // );
        }),
      // setTree: (payload, section, id) =>
      //   set((state) => {
      //     state.cpvTrees[section][id] = payload;
      //   }),

      addDefaultCPVData: (section, id) =>
        set((state) => {
          state.cpvTrees[section][id] = all_cpv_trees[i18n.language.slice(0, 2)];
          state.refreshCPVTree[section][id] = true;
        }),
      transferCPVData: (section, oldID, NewID) =>
        set((state) => {
          state.cpvTrees[section][NewID] = {
            ...state.cpvTrees[section][oldID],
          };
          state.refreshCPVTree[section][NewID] = true;
          delete state.cpvTrees[section][oldID];
          delete state.refreshCPVTree[section][oldID];
        }),

      setRefreshCPVTree: (section, id, payload) =>
        set((state) => {
          state.refreshCPVTree[section][id] = payload;
        }),

      setCPVStage: (payload) =>
        set((state) => {
          state.CPVStage = payload;
        }),
      // ------------------- OTHER -----------------------
      setTutorialEnabled: (payload) =>
        set(() => ({ tutorialEnabled: payload })),
      resetState: () => {
        set(initialState);
      },
    })),
    {
      partialize: (state) =>
        Object.fromEntries(
          Object.entries(state).filter(
            ([key]) =>
              !["_hasHydrated", "CPVStage", "refreshCPVTree"].includes(key)
          )
        ),
      name: "cpvStore",
      storage: createJSONStorage(() => storage),
      version: process.env.REACT_APP_VERSION,
      merge: (persistedState, currentState) =>
        mergeDeepLeft(persistedState, currentState),
      onRehydrateStorage: (state) => {
        // devDebug("cpvStore -> hydration starts, state: ", state);
        return (state, error) => {
          if (error) {
            // devDebug("cpvStore -> an error happened during hydration", error);
          } else {
            // devDebug("cpvStore -> hydration finished, state: ", state);
            state.setHasHydrated(true);
          }
        };
      },
    }
  ),
  shallow
);
