import { createWithEqualityFn } from "zustand/traditional";
import { shallow } from "zustand/shallow";
import { immer } from "zustand/middleware/immer";
// import i18n from "i18next";
// import cloneDeep from "lodash/cloneDeep";

// define the initial state
const initialState = {
  favourites: [],
  favouritesStatus: "loading",
  favouritesError: "",
  favouriteInProgressIDs: {},
};

export const useMyTendersStore = createWithEqualityFn(
  immer((set, get) => ({
    ...initialState,
    // ------------------- Favourites --------------
    setFavourites: (favourites) =>
      set((state) => {
        state.favourites = favourites;
      }),
    setFavouriteInProgressIDs: (tenderID) =>
      set((state) => {
        if (state.favouriteInProgressIDs[tenderID]) {
          delete state.favouriteInProgressIDs[tenderID]
        } else {
          state.favouriteInProgressIDs[tenderID] = "loading"
        }

      }),
    setFavouritesStatus: (favouritesStatus) =>
      set((state) => {
        state.favouritesStatus = favouritesStatus;
      }),
    setFavouritesError: (favouritesError) =>
      set((state) => {
        state.favouritesError = favouritesError;
      }),
  })),
  shallow
);
