import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../Services/auth";
import devDebug from "../../Helpers/debug";
import { useComponentsStore } from "../../Stores/componentsStore";

export default function UpgradeModal() {
  devDebug("--- Rendering --- Upgrade Modal");
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const [showUpgradePopup, setShowUpgradePopup] = useComponentsStore(
    (state) => [state.showUpgradePopup, state.setShowUpgradePopup]
  );
  const auth = useAuth();
  const apiService = auth.apiService;
  const redirect = (resp) => {
    window.location.replace(resp.url);
    setLoading(false);
  };

  const CustomerPortalError = () => {
    setLoading(false);
  };

  const goToBillingPortal = () => {
    devDebug("upgradeModal -> Going to customer portal");
    setLoading(true);
    localStorage.setItem("reload_after_stripe", true);

    const controller = new AbortController();
    apiService.axiosPOST(
      `api/payment/stripe/customer-portal`, //endpoint
      controller.signal, //signal
      redirect, //successFn
      auth.newErrorHandler, //error AlerterFn
      "Error accessing your billing settings", //error message
      CustomerPortalError, //errorFn (gets error back)
      {
        payload: {
          return_url: `${window.location.origin}/user_preferences#stripe_return`,
        },
        section: "Upgrade Modal -> goToBillingPortal",
      } //data (gets handed back to success fn)
    );
  };

  return (
    <Modal
      isOpen={showUpgradePopup}
      role="dialog"
      autoFocus={true}
      centered={true}
      className=""
      tabIndex="-1"
      zIndex="11"
      toggle={() => {
        setShowUpgradePopup(!showUpgradePopup);
      }}
    >
      <div className="modal-content">
        <ModalHeader
          tag="h1"
          className="ta-modals-header"
          toggle={() => {
            setShowUpgradePopup(!showUpgradePopup);
          }}
        >
          {t("Upgrade")}
        </ModalHeader>
        <ModalBody>
          <div className="th-subscribe-message">
            {t(
              "Your subscription does not include this content. Please upgrade your subscription to get access."
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            onClick={goToBillingPortal}
            className="btn btn-primary btn-block mt-4"
            type="submit"
          >
            {loading ? (
              <div>
                <div
                  className="spinner-border tc-small-spinner text-light"
                  role="status"
                ></div>
                {t("Loading...")}
              </div>
            ) : (
              t("Upgrade")
            )}
          </button>
        </ModalFooter>
      </div>
    </Modal>
  );
}
