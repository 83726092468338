import devDebug from "../../../Helpers/debug";
import { updateLocalStorageFilters } from "../../../Helpers/updateLocalStorageFilters";
import { useComponentsStore } from "../../../Stores/componentsStore";
import { useTenderSearchStore } from "../../../Stores/tenderSearch";
import cloneDeep from "lodash/cloneDeep";
import default_mapdata from "../../../Assets/Mapdata/default_mapdata.json";

const refreshingTableSuccess = (resp) => {
  const setRefreshingTable = useTenderSearchStore.getState().setRefreshingTable;
  const setTenders = useTenderSearchStore.getState().setTenders;

  setRefreshingTable(false);
  setTenders(resp);
};

const refreshingTableError = (err, data) => {
  const setRefreshingTable = useTenderSearchStore.getState().setRefreshingTable;
  setRefreshingTable(false);
  if (data.tableParamRef.current[data.tab].page !== 1) {
    refreshTenderSearchTable(data.auth, data.tabName, true, data.tableParamRef, data.tableSettingsName, data.admin); //true is resetPage
    data.tableParamRef.current[data.tab].page = 1;
    updateLocalStorageFilters(data.tableSettingsName, data.tableParamRef.current);
  }
};

function overWriteAllColumnSelections(tableParamRef, tableSettingsName, tabName) {
  devDebug("Triggered overWriteAllColumnSelections");
  const tab = useTenderSearchStore.getState().tab[tabName];

  let prevtableQueryParams = cloneDeep(tableParamRef.current);

  for (let i = 0; i < prevtableQueryParams.length; i++) {
    prevtableQueryParams[i] = cloneDeep(prevtableQueryParams[tab]);
  }

  tableParamRef.current = prevtableQueryParams;
  updateLocalStorageFilters(tableSettingsName, prevtableQueryParams); //handing this in direct, to make sure it's current
}

export const refreshTenderSearchTable = (auth, tabName, resetPage, tableParamRef, tableSettingsName, admin) => {
  const controller = new AbortController();

  const setRefreshingTable = useTenderSearchStore.getState().setRefreshingTable;
  const tenderStrategies = useTenderSearchStore.getState().tenderStrategies;
  const nutsSearchFromDashboardClick = useComponentsStore.getState().nutsSearchFromDashboardClick;
  const setNutsSearchFromDashboardClick = useComponentsStore.getState().setNutsSearchFromDashboardClick;
  const setRandomReinit = useTenderSearchStore.getState().setRandomReinit;

  const urlPrefix = admin ? "tenders/" : "tender/search/";

  setRandomReinit();
  const tab = useTenderSearchStore.getState().tab[tabName];
  const applyToAllTabs = useTenderSearchStore.getState().applyToAllTabs;

  if (applyToAllTabs) {
    overWriteAllColumnSelections(tableParamRef, tableSettingsName, tabName);
  }

  let filterColumns = tableParamRef.current[tab].filterColumns;
  let searchColumns = tableParamRef.current[tab].searchColumns;
  setRefreshingTable(true);
  let pageNum = tableParamRef.current[tab].page;
  if (resetPage) {
    pageNum = 1;
    tableParamRef.current[tab].page = 1;
    updateLocalStorageFilters(tableSettingsName, tableParamRef.current);
  }

  const no_closing_date = tableParamRef.current[tab].no_closing_date;
  const closing_start = no_closing_date ? "" : tableParamRef.current[tab].closing_date_start;
  const closing_end = no_closing_date ? "" : tableParamRef.current[tab].closing_date_end;
  const confirmed = admin ? `&confirmed=${tableParamRef.current[tab].confirmed}` : "";
  const confirmation_state = admin
    ? `&confirmation_state=${
        tableParamRef.current[tab].confirmation_state === "all" ? "" : tableParamRef.current[tab].confirmation_state
      }`
    : "";

  const notice_type = admin
    ? `&${tableParamRef.current[tab].notice_type.map((n) => `notice_type=${n.value}`).join("&")}`
    : "";

  if (nutsSearchFromDashboardClick) {
    tableParamRef.current[tab].nut = nutsSearchFromDashboardClick;
    tableParamRef.current[tab].nutDisplay = default_mapdata[nutsSearchFromDashboardClick].name;
    setNutsSearchFromDashboardClick("");
  }

  const url = `${urlPrefix}?${tab !== 0 ? `strategy_id=${tenderStrategies[tab].id}` : ``}&page=${pageNum}&page_size=${
    tableParamRef.current[tab].page_size
  }${confirmed}${confirmation_state}${notice_type}&ordering=${tableParamRef.current[tab].orderingURL}&min_value=${
    tableParamRef.current[tab].min_value
  }&max_value=${tableParamRef.current[tab].max_value}&search=${tableParamRef.current[tab].search}&max_value=${
    tableParamRef.current[tab].max_value
  }&nut=${tableParamRef.current[tab].nut}&columns=${searchColumns
    .filter((n) => !filterColumns.includes(n))
    .join(",")}&closing_after=${closing_start}&closing_before=${closing_end}&publication_after=${
    tableParamRef.current[tab].publication_date_start
  }&publication_before=${tableParamRef.current[tab].publication_date_end}&no_closing_date=${no_closing_date}`;

  auth.apiService.axiosGET(
    url,
    controller.signal, //signal
    refreshingTableSuccess, //successFn
    auth.newErrorHandler, //error AlerterFn
    "There was an error retrieving tenders", //error message
    refreshingTableError, //errorFn (gets error back)
    {
      section: "Tender Search -> refreshingTable",
      tableParamRef: tableParamRef,
      auth: auth,
      tab: tab,
      admin: admin,
      tabName: tabName,
      tableSettingsName: tableSettingsName,
    } //data (gets handed back to success fn)
  );
};
