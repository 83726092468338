import React from "react";
import { Container, Row } from "reactstrap";
import { useTranslation } from "react-i18next";
import cookie_policy from "../Assets/Docs/cookie_policy.pdf";
import privacy_policy_en from "../Assets/Docs/privacy_policy_en.pdf";
import privacy_policy_it from "../Assets/Docs/privacy_policy_it.pdf";
import t_and_cs from "../Assets/Docs/t_and_cs.pdf";
// import devDebug from "../Helpers/debug";
import { useComponentsStore } from "../Stores/componentsStore";
import Icon from "@mdi/react";
import { mdiLinkedin, mdiTwitter } from "@mdi/js";
export default function Footer() {
  const [helpVideoAction, showHelpVideoModal] = useComponentsStore((state) => [
    state.helpVideoAction,
    state.showHelpVideoModal,
  ]);
  const { t, i18n } = useTranslation();

  function returnPrivacyPolicy() {
    switch (i18n.language.slice(0, 2)) {
      case "en":
        return (
          <a
            className="th-privacy-link tc-footer-privacy-policy"
            href={privacy_policy_en}
            download="privacy_policy.pdf"
          >
            {t("Privacy Policy")}
          </a>
        );
      case "it":
        return (
          <a
            className="th-privacy-link tc-footer-privacy-policy"
            href={privacy_policy_it}
            download="privacy_policy.pdf"
          >
            {t("Privacy Policy")}
          </a>
        );
      default:
        return (
          <a
            className="th-privacy-link tc-footer-privacy-policy"
            href={privacy_policy_en}
            download="privacy_policy.pdf"
          >
            {t("Privacy Policy")}
          </a>
        );
    }
  }

  return (
    <footer className="footer">
      <Container fluid={true}>
        <Row>
          <div className="th-footer-font-size tc-footer-grid">
            <a
              className=""
              target="_blank"
              href="https://blog.tenders.club"
              rel="noreferrer"
            >
              {t("Tenders Club Blog")}
            </a>
            {returnPrivacyPolicy()}
            <a
              className="th-privacy-link tc-footer-cookie-policy"
              href={cookie_policy}
              download="cookie_policy.pdf"
            >
              {t("Cookie Policy")}
            </a>

            <a
              className="th-privacy-link tc-footer-t-and-cs"
              href={t_and_cs}
              download="terms_and_conditions.pdf"
            >
              {t("Terms & Conditions")}
            </a>
            <a
              className="th-footer-font-size tc-footer-mail-link"
              href="mailto:info@ufficioappalti.com"
            >
              {"info@ufficioappalti.com"}
            </a>

            <div className="tc-footer-divider-line"></div>

            <div className="th-copyright-line tc-footer-copyright">
              {new Date().getFullYear()} © {t("App Title")}.
            </div>
            <div
              className="th-footer-font-size tc-footer-help-link tc-link"
              onClick={() => {
                helpVideoAction(!showHelpVideoModal);
              }}
            >
              {t("Help")}
            </div>
            <div className="d-flex justify-content-end">
              <a
                href={t("linkedin_link")}
                target="_blank"
                className=""
                rel="noreferrer"
              >
                <Icon
                  className=" hover:text-tc_purple-500 hover:dark:text-tc_grey-300"
                  path={mdiLinkedin}
                  size={1}
                />
              </a>
              <a
                href="https://twitter.com/TendersClub"
                target="_blank"
                className=""
                rel="noreferrer"
              >
                <Icon
                  className=" hover:text-tc_purple-500 hover:dark:text-tc_grey-300"
                  path={mdiTwitter}
                  size={1}
                />
              </a>
            </div>
            <div className="tc-footer-central-company-number">{`${t(
              "Company Number"
            )}: 0292853681`}</div>
          </div>
        </Row>
      </Container>
    </footer>
  );
}
