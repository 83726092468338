import { createWithEqualityFn } from "zustand/traditional";
import { shallow } from "zustand/shallow";
import { immer } from "zustand/middleware/immer";
import i18n from "i18next";
import cloneDeep from "lodash/cloneDeep";
import dayjs from "dayjs";
// import { current } from "immer";

const localStorageKey = `${process.env.REACT_APP_VIEW_TENDER_TAB_STATE_KEY}_${process.env.REACT_APP_VERSION}`;
const CPVWeightLocalStorageKey = `${process.env.REACT_APP_VIEW_TENDER_ANALYTICS_CPV_WEIGHT_KEY}_${process.env.REACT_APP_VERSION}`;
const NUTWeightLocalStorageKey = `${process.env.REACT_APP_VIEW_TENDER_ANALYTICS_NUTS_WEIGHT_KEY}_${process.env.REACT_APP_VERSION}`;

function getWeightsVal(type) {
  let key = type === "CPV" ? CPVWeightLocalStorageKey : NUTWeightLocalStorageKey;
  let res = {};
  if (localStorage.getItem(key)) {
    res = JSON.parse(localStorage.getItem(key));
    return res;
  } else {
    writeWeights(type, res);
    return res;
  }
}

function writeWeights(type, weightObj) {
  let key = type === "CPV" ? CPVWeightLocalStorageKey : NUTWeightLocalStorageKey;
  localStorage.setItem(key, JSON.stringify(weightObj));
}

function getCurrentTab() {
  let res = {};
  if (localStorage.getItem(localStorageKey)) {
    res = JSON.parse(localStorage.getItem(localStorageKey));
    return res;
  } else {
    writeCurrentTab(res);
    return res;
  }
}

function writeCurrentTab(tabObj) {
  localStorage.setItem(localStorageKey, JSON.stringify(tabObj));
}

export const defaultTender = {
  id: 0,
  lot: [],
  cpv: [],
  attachment: [],
  short_description: "",
  text: "",
  tender_national_id: "",
  estimated_total_value: "",
  currency: "",
  tendering_authority: { name: "", id: null, nut_code: "" },
  tender_url: "",
  nut_code: "",
  translations: {
    it: {
      title: "",
      procedure_type: "",
      award_criteria: "",
    },
    es: {
      title: "",
      procedure_type: "",
      award_criteria: "",
    },
    fr: {
      title: "",
      procedure_type: "",
      award_criteria: "",
    },
    de: {
      title: "",
      procedure_type: "",
      award_criteria: "",
    },
    en: {
      title: "",
      procedure_type: "",
      award_criteria: "",
    },
  },
  nut_label: "",
  publication_date: "",
  closing_date: "",
  original_language: "",
  platform_id: "",
  procedure_title: "",
  tendering_authority__nut_code: "",
  soa_categories: [],
  soa_category_labels: [],
  allow_public_view: false,
};

export const defaultTenderPrefilled = {
  id: null,
  lot: [],
  cpv: [],
  attachment: [],
  short_description: "No data",
  text: "No data",
  tender_national_id: "",
  estimated_total_value: "",
  currency: "",
  tendering_authority: { name: "", id: null, nut_code: "" },
  tender_url: "",
  nut_code: "IT",
  translations: {
    it: {
      title: "",
      procedure_type: "no data",
      award_criteria: "no data",
    },
    es: {
      title: "",
      procedure_type: "",
      award_criteria: "",
    },
    fr: {
      title: "",
      procedure_type: "",
      award_criteria: "",
    },
    de: {
      title: "",
      procedure_type: "",
      award_criteria: "",
    },
    en: {
      title: "",
      procedure_type: "",
      award_criteria: "",
    },
  },
  nut_label: "",
  publication_date: dayjs().format("YYYY-MM-DD"),
  closing_date: dayjs().format("YYYY-MM-DD"),
  original_language: "it",
  platform_id: "",
  procedure_title: "",
  procedure_type: "",
  award_criteria: "",
  tendering_authority__nut_code: "",
  soa_categories: [],
  soa_category_labels: [],
  allow_public_view: false,
};

const defaultLot = {
  tender_national_id: "",
  value: "",
  description: "",
  cpv: " - ",
};

// define the initial state
const initialState = {
  averageDiscountForCPV: 0,
  averageDiscountForNUT: 0,
  selectedCPVs: [],
  applyNewTenderingAuthority: false,
  showAddNut: false,
  tenderingAuthoritySelection: {},
  tenderingAuthorities: [],
  loadTenderingAuthorities: true,
  tender: defaultTender,
  currentAttachment: {
    path: "",
    description: "",
  },
  tenderStatus: "loading",
  submitting: false,
  cpvStatus: "loading",
  currentLang: "en",
  noInfoMsg: i18n.t("Information not available"),
  errors: {},
  level: 0,
  currentTab: getCurrentTab(),
  CPVWeights: getWeightsVal("CPV"),
  NUTWeights: getWeightsVal("NUT"),
  defaultCPVWeight: 0,
  defaultNUTWeight: 0,
  fields: [],
  fieldsStatus: "loading",
  fieldsError: "",
  fieldSelectionWizardModalVisible: false,
  documentsModalVisible: false,
  summaryTemplateModalVisible: false,
  templates: [],
  templatesStatus: "loading",
  templatesError: "",
  selectedDocuments: [],
  summaries: [],
  summariesStatus: "loading",
  summariesError: "",
  summaryIDToGET: 0,
  summaryResultModalVisible: false,
  summary: {},
  editableSummary: {},
  summaryStatus: "loading",
  summaryError: "",
  summaryNameToGET: "",
  summaryFieldValues: [],
  summaryFieldValuesStatus: "loading",
  summaryFieldValuesError: "",
  keyOfFileToBeUploaded: null,
  alreadySubmittedModalVisible: false,
  usersList: [],
  search: "",
  userStatus: "",
  user: {},
  attachments: [],
  attachmentsStatus: "loading",
  attachmentsError: "",
  attachmentToBeEdited: {},
  editAttachmentModalVisible: false,
  attachmentMode: "add",
  lots: [],
  lotsStatus: "loading",
  lotsError: "",
  deletingLot: null,
  editingLot: null,
  savingLot: null,
  lotError: null,
  PDFdelay: true,
  awardCriteria: {},
  awardCriteriaStatus: "loading",
  procedureTypes: {},
  procedureTypesStatus: "loading",
  meilisearchToken: null,
  meilisearchTokenStatus: "loading",
  meilisearchIndex: null,
  tenderingAuthorityForInputDisplayOnly: "",
  showQueryHistoryItems: {},
  queryHistoryItems: [],
  tenderError: "",
  regeneratedValues: {},
  inputRefresh: null,
  generatableFields: [],
};

export const useViewEditTenderStore = createWithEqualityFn(
  immer((set, get) => ({
    ...initialState,
    // ------------------- HISTORY -------------------
    setQueryHistoryItems: (items) =>
      set((state) => {
        state.queryHistoryItems = items;
      }),
    setShowQueryHistoryItems: (field) =>
      set((state) => {
        if (state.showQueryHistoryItems[field]) {
          if (state?.showQueryHistoryItems?.[field]) {
            state.showQueryHistoryItems[field] = false;
          } else {
            state.showQueryHistoryItems[field] = true;
          }
        } else {
          state.showQueryHistoryItems[field] = true;
        }
      }),
    // ------------------- AWARD CRITERIA / PROCEDURE TYPES -------------------
    setAwardCriteria: (awardCriteria) =>
      set((state) => {
        state.awardCriteria = awardCriteria;
      }),
    setProcedureTypes: (procedureTypes) =>
      set((state) => {
        state.procedureTypes = procedureTypes;
      }),
    setAwardCriteriaStatus: (awardCriteriaStatus) =>
      set((state) => {
        state.awardCriteriaStatus = awardCriteriaStatus;
      }),
    setProcedureTypesStatus: (procedureTypesStatus) =>
      set((state) => {
        state.procedureTypesStatus = procedureTypesStatus;
      }),
    // ------------------- LOTS -------------------
    changeLot: (id, value, key) =>
      set((state) => {
        for (let lot of state.lots) {
          if (lot.id === id) {
            lot[key] = value;
          }
        }
      }),
    deleteLot: (id) =>
      set((state) => {
        state.lots = state.lots.filter((lot) => lot.id !== id);
      }),
    addLot: () =>
      set((state) => {
        const id = `${Math.random()}_new`;
        state.lots = [{ ...defaultLot, id: id }, ...state.lots];
        state.editingLot = id;
      }),
    setLotError: (lotID) =>
      set((state) => {
        state.lotError = lotID;
      }),
    setSavingLot: (lotID) =>
      set((state) => {
        state.savingLot = lotID;
      }),
    setDeletingLot: (lotID) =>
      set((state) => {
        state.deletingLot = lotID;
      }),
    setEditingLot: (lotID) =>
      set((state) => {
        state.editingLot = lotID;
      }),
    setLot: (newLot, id) =>
      set((state) => {
        for (let lot of state.lots) {
          if (lot.id === id) {
            lot = newLot;
          }
        }
      }),
    setLots: (lots) =>
      set((state) => {
        state.lots = lots;
      }),
    setLotsStatus: (lotsStatus) =>
      set((state) => {
        state.lotsStatus = lotsStatus;
      }),
    setLotsError: (lotsError) =>
      set((state) => {
        state.lotsError = lotsError;
      }),
    // ------------------- VIEW / EDIT TENDER --------------
    setTender: (payload) =>
      set((state) => {
        delete state.tender;
        state.tender = payload;
        state.tenderingAuthorityForInputDisplayOnly = payload?.tendering_authority?.name || ""; //needed for pre-populating TA search input
      }),
    setTenderError: (err) =>
      set((state) => {
        state.tenderError = err;
      }),
    setCurrentTab: (payload) =>
      set((state) => {
        state.currentTab[state.tender.id] = payload;
        writeCurrentTab(state.currentTab);
      }),

    setDefaultCPVWeight: (payload) =>
      set((state) => {
        state.defaultCPVWeight = payload;
      }),
    setDefaultNUTWeight: (payload) =>
      set((state) => {
        state.defaultNUTWeight = payload;
      }),
    setCPVWeight: (payload) =>
      set((state) => {
        state.CPVWeights[state.tender.id] = payload;
        writeWeights("CPV", state.CPVWeights);
      }),
    setNUTWeight: (payload) =>
      set((state) => {
        state.NUTWeights[state.tender.id] = payload;
        writeWeights("NUT", state.NUTWeights);
      }),
    setAverageDiscountForCPV: (payload) =>
      set((state) => {
        state.averageDiscountForCPV = payload;
      }),
    setAverageDiscountForNUT: (payload) =>
      set((state) => {
        state.averageDiscountForNUT = payload;
      }),
    setConfirmed: (payload) =>
      set((state) => {
        state.tender.confirmed = payload;
      }),
    addSOACategory: (payload) =>
      set((state) => {
        if (!state.tender.soa_categories.includes(payload.code)) {
          state.tender.soa_categories = [...state.tender.soa_categories, payload.code];
        }
        let included = false;
        for (let cat of state.tender.soa_category_labels) {
          if (cat.code === payload.code) {
            included = true;
          }
        }
        if (!included) {
          state.tender.soa_category_labels = [...state.tender.soa_category_labels, payload];
        }
      }),
    removeSOACategory: (payload) =>
      set((state) => {
        state.tender.soa_categories = state.tender.soa_categories.filter((soa) => soa !== payload.code);
        state.tender.soa_category_labels = state.tender.soa_category_labels.filter((soa) => soa.code !== payload.code);
      }),
    setAllowPublicView: (payload) =>
      set((state) => {
        state.tender.allow_public_view = payload;
      }),
    setSubmitting: (payload) =>
      set((state) => {
        state.submitting = payload;
      }),
    setErrors: (payload) =>
      set((state) => {
        state.errors = payload;
      }),
    setNoInfoMsg: (payload) =>
      set((state) => {
        state.noInfoMsg = payload;
      }),
    setCurrentLang: (payload) =>
      set((state) => {
        state.currentLang = payload;
      }),
    setTenderStatus: (payload) =>
      set((state) => {
        state.tenderStatus = payload;
      }),
    setTenderConfirmationStatus: (payload) =>
      set((state) => {
        state.tender.confirmation_state = payload;
      }),
    setTenderAwardCriteria: (award_criteria) =>
      set((state) => {
        state.tender.award_criteria = award_criteria;
      }),
    setTenderProcedureType: (procedure_type) =>
      set((state) => {
        state.tender.procedure_type = procedure_type;
      }),
    setShowRegeneratedTenderValue: (name, value) =>
      set((state) => {
        if (!state.regeneratedValues[state.tender.id]) {
          state.regeneratedValues[state.tender.id] = {};
        }
        state.regeneratedValues[state.tender.id][name] = value;
      }),
    deleteRegeneratedValue: (name) =>
      set((state) => {
        if (state?.regeneratedValues?.[state?.tender?.id]) {
          delete state.regeneratedValues[state.tender.id][name];
        }
      }),
    applyRegeneratedValue: (name, value) =>
      set((state) => {
        let lang = state.currentLang;
        if (state.currentLang === "og") {
          lang = state.tender.original_language;
        }
        if (!state?.tender?.translations?.[lang]) {
          state.tender.translations[lang] = {};
        }
        if (name === "award_criteria" || name === "title") {
          state.tender.translations[lang][name] = value;
        } else {
          state.tender[name] = value;
        }
        state.inputRefresh = Math.random();
      }),
    refreshInputs: () =>
      set((state) => {
        state.inputRefresh = Math.random();
      }),
    setGeneratableFields: (generatableFields) =>
      set((state) => {
        state.generatableFields = generatableFields;
      }),
    setNewTenderValue: (e) =>
      set((state) => {
        let value;
        if (e.target.value === "true" || e.target.value === "false") {
          if (e.target.value === "true") {
            value = true;
          } else {
            value = false;
          }
        } else {
          value = e.target.value;
        }
        if (e.target.id === "other_confirmed") {
          e.target.id = "confirmed";
        }
        if (e.target.id === "other_allow_public_view") {
          e.target.id = "allow_public_view";
        }
        if (e.target.lang) {
          if (e.target.lang === "og") {
            e.target.lang = state.tender.original_language;
          }
          if (state.tender.translations[e.target.lang]) {
            state.tender.translations[e.target.lang][e.target.id] = value;
          } else {
            state.tender.translations[e.target.lang] = {
              title: "",
              procedure_type: "",
              award_criteria: "",
            };
            state.tender.translations[e.target.lang][e.target.id] = value;
          }
        } else {
          state.tender[e.target.id] = value;
        }
      }),
    // ------------------- NUTS --------------
    setNutCode: (payload) =>
      set((state) => {
        state.tender.nut_code = payload;
        if (state?.errors?.nut_code) {
          state.errors.nut_code = "";
        }
      }),
    setMapLevel: (payload) =>
      set((state) => {
        state.level = payload;
      }),

    // ------------------- TENDERING AUTHORITIES --------------
    setMeilisearchIndex: (index) =>
      set((state) => {
        state.meilisearchIndex = index;
      }),
    setMeilisearchToken: (token) =>
      set((state) => {
        state.meilisearchToken = token;
      }),
    setMeilisearchTokenStatus: (status) =>
      set((state) => {
        state.meilisearchTokenStatus = status;
      }),
    setTenderingAuthorityForInputDisplayOnly: (ta) =>
      set((state) => {
        state.tenderingAuthorityForInputDisplayOnly = ta;
      }),
    setTenderingAuthority: (ta) =>
      set((state) => {
        state.tender.tendering_authority.name = ta;
      }),
    setTenderingAuthorityNutCode: (nut_code) =>
      set((state) => {
        state.tender.tendering_authority.nut_code = nut_code;
      }),
    setTenderTenderingAuthorityID: (ta_id) =>
      set((state) => {
        state.tender.tendering_authority_id = ta_id; //state.tender.tendering_authority.id is readonly
      }),
    setTenderingAuthorities: (payload) =>
      set((state) => {
        state.tenderingAuthorities = payload;
      }),
    setLoadTenderingAuthorities: (payload) =>
      set((state) => {
        state.loadTenderingAuthorities = payload;
      }),
    toggleAddNut: (payload) =>
      set((state) => {
        state.showAddNut = payload;
      }),
    newTenderingAuthoritySelected: (ta) =>
      set((state) => {
        state.tenderingAuthoritySelection = ta;
      }),
    // ------------------- CPVs --------------
    setCPVs: (payload) =>
      set((state) => {
        state.tender.cpv = payload;
      }),
    deleteCPV: (payload) =>
      set((state) => {
        state.tender.cpv = state.tender.cpv.filter((cpv) => cpv !== payload);
      }),
    // ------------------- ATTACHMENTS --------------
    setAttachments: (attachments) =>
      set((state) => {
        state.attachments = attachments;
      }),
    setAttachmentsStatus: (attachmentsStatus) =>
      set((state) => {
        state.attachmentsStatus = attachmentsStatus;
      }),
    setAttachmentsError: (attachmentsError) =>
      set((state) => {
        state.attachmentsError = attachmentsError;
      }),
    setEditAttachmentModalVisible: (payload) =>
      set((state) => {
        state.editAttachmentModalVisible = payload;
      }),
    setAttachmentMode: (attachmentMode) =>
      set((state) => {
        state.attachmentMode = attachmentMode;
      }),
    changeAttachmentAttribute: (name, value) =>
      set((state) => {
        state.attachmentToBeEdited[name] = value;
      }),
    setAttachment: (attachment) =>
      set((state) => {
        state.tender.attachment[Math.random()] = attachment;
      }),
    setAttachmentToBeEdited: (attachmentToBeEdited) =>
      set((state) => {
        state.attachmentToBeEdited = attachmentToBeEdited;
      }),

    // !!!!!!!!!! ------- keep the below code in case it's useful elsewhere ---------- !!!!!!!!!!!!!!
    // addEmptyAttachment: () =>
    //   set((state) => {

    //     let newAttachment = {
    //       description: "",
    //       path: "",
    //       new: true,
    //     };
    //     const entrySet = Object.entries(state.tender.attachment);
    //     entrySet.splice(0, 0, [Math.random(), newAttachment]);
    //     state.tender.attachment = Object.fromEntries(entrySet);

    //   }),
    // ------------------- SUMMARIES / VADEMECUM --------------
    setUser: (payload) =>
      set((state) => {
        state.user = payload;
      }),
    setAlreadySubmittedModalVisible: (payload) =>
      set((state) => {
        state.alreadySubmittedModalVisible = payload;
      }),
    setUsersList: (payload) =>
      set((state) => {
        state.usersList = payload;
      }),
    setSearch: (payload) =>
      set((state) => {
        state.search = payload;
      }),
    setUserStatus: (payload) =>
      set((state) => {
        state.userStatus = payload;
      }),
    setSummaries: (payload) =>
      set((state) => {
        state.summaries = payload;
      }),
    setSummariesStatus: (payload) =>
      set((state) => {
        state.summariesStatus = payload;
      }),
    setSummariesError: (payload) =>
      set((state) => {
        state.summariesError = payload;
      }),
    setSummaryIDToGET: (summaryIDToGET) =>
      set((state) => {
        state.summaryIDToGET = summaryIDToGET;
      }),
    setSummaryResultModalVisible: (summaryResultModalVisible) =>
      set((state) => {
        state.summaryResultModalVisible = summaryResultModalVisible;
      }),
    setSummary: (summary) =>
      set((state) => {
        state.summary = summary;
        state.editableSummary = summary;
      }),
    setSummaryStatus: (summaryStatus) =>
      set((state) => {
        state.summaryStatus = summaryStatus;
      }),
    setSummaryError: (summaryError) =>
      set((state) => {
        state.summaryError = summaryError;
      }),
    setSummaryNameToGET: (summaryNameToGET) =>
      set((state) => {
        state.summaryNameToGET = summaryNameToGET;
      }),
    setSummaryState: (value) =>
      set((state) => {
        state.editableSummary.state = value;
      }),
    setSummaryFieldValues: (values) =>
      set((state) => {
        state.summaryFieldValues = values.slice().sort((a, b) => {
          return a.position > b.position;
        });
      }),
    setSummaryFieldValue: (id, value) =>
      set((state) => {
        let newVals = cloneDeep(state.summaryFieldValues);
        let idx = 0;
        for (let i = 0; i < newVals.length; i++) {
          if (`${newVals[i].id}` === `${id}`) {
            idx = i;
            break;
          }
        }

        newVals[idx] = value;
        state.summaryFieldValues = newVals;
      }),
    setPDFDelay: (PDFdelay) =>
      set((state) => {
        state.PDFdelay = PDFdelay;
      }),
    setSummaryFieldValuesStatus: (status) =>
      set((state) => {
        state.summaryFieldValuesStatus = status;
      }),
    setSummaryFieldValuesError: (err) =>
      set((state) => {
        state.summaryFieldValuesError = err;
      }),
    setSelectedDocuments: (payload) =>
      set((state) => {
        state.selectedDocuments = payload;
      }),
    setFieldSelectionWizardModalVisible: (payload) =>
      set((state) => {
        state.fieldSelectionWizardModalVisible = payload;
      }),
    setDocumentsModalVisible: (payload) =>
      set((state) => {
        state.documentsModalVisible = payload;
      }),
    setSummaryTemplateModalVisible: (payload) =>
      set((state) => {
        state.summaryTemplateModalVisible = payload;
      }),
    setTemplates: (payload) =>
      set((state) => {
        state.templates = payload;
      }),
    setTemplatesStatus: (payload) =>
      set((state) => {
        state.templatesStatus = payload;
      }),
    setTemplatesError: (payload) =>
      set((state) => {
        state.templatesError = payload;
      }),
    setFields: (payload) =>
      set((state) => {
        state.fields = payload;
      }),
    setFieldsStatus: (payload) =>
      set((state) => {
        state.fieldsStatus = payload;
      }),
    setFieldsError: (payload) =>
      set((state) => {
        state.fieldsError = payload;
      }),
  })),
  shallow
);
