import summary_pdf_shapes from "../../../../../../Assets/Images/summary_pdf_shapes.png";
import tenders_club_tagline from "../../../../../../Assets/Images/tenders_club_tagline.png";
import tenders_club_logo_only from "../../../../../../Assets/Images/tenders_club_logo_only.png";
import dayjs from "dayjs";
import {
  Document,
  Page,
  Text,
  StyleSheet,
  Image,
  Font,
  View,
} from "@react-pdf/renderer";

import { useTranslation } from "react-i18next";
import ItemsTable from "../ItemsTable";

Font.register({
  family: "Oswald",
  src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
});

Font.register({
  family: "Montserrat",
  fonts: [
    {
      src: "https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Ew-.ttf",
    },
    {
      src: "https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCu170w-.ttf",
      fontWeight: 600,
    },
  ],
});

export const SummaryPDF = ({
  email,
  summaryNameToGET,
  summaryFieldValues,
  tenderTitle,
}) => {
  const { t, i18n } = useTranslation();

  const returnPreparedData = () => {
    let preppedData = [];

    for (let i = 0; i < summaryFieldValues.length; i++) {
      if (summaryFieldValues[i + 1]) {
        if (
          summaryFieldValues[i].value !== `""` &&
          summaryFieldValues[i].value !== "" &&
          summaryFieldValues[i].value.length > 0 &&
          summaryFieldValues[i].value.length < 100 &&
          summaryFieldValues[i].return_type === "TEXT" &&
          summaryFieldValues[i + 1].value !== `""` &&
          summaryFieldValues[i + 1].value !== "" &&
          summaryFieldValues[i + 1].value.length > 0 &&
          summaryFieldValues[i + 1].value.length < 100 &&
          summaryFieldValues[i + 1].return_type === "TEXT"
        ) {
          preppedData.push({
            twoColumns: true,
            values: [summaryFieldValues[i], summaryFieldValues[i + 1]],
          });
          i = i + 1;
          continue;
        } else {
          if (
            summaryFieldValues[i].value !== `""` &&
            summaryFieldValues[i].value !== "" &&
            summaryFieldValues[i].value.length > 0
          ) {
            preppedData.push({
              twoColumns: false,
              values: [summaryFieldValues[i]],
            });
          }
        }
      } else {
        if (
          summaryFieldValues[i].value !== `""` &&
          summaryFieldValues[i].value !== "" &&
          summaryFieldValues[i].value.length > 0
        ) {
          preppedData.push({
            twoColumns: false,
            values: [summaryFieldValues[i]],
          });
        }
      }
    }

    return preppedData;
  };

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={[styles.doubleColumnView, styles.justifyBetween]}>
          <Text style={styles.summaryNameAtTop} fixed>{`${t(
            "Summary"
          )}: ${summaryNameToGET}`}</Text>
          <Text style={styles.dateAtTop} fixed>
            {dayjs().format("DD/MM/YYYY")}
          </Text>
        </View>
        <View style={styles.Header}>
          <Image style={styles.headerImage} src={summary_pdf_shapes} fixed />
          <View style={styles.headerLogoFlex}>
            <Image
              style={styles.headerImage}
              src={tenders_club_tagline}
              fixed
            />
            <Image
              style={styles.headerImage}
              src={tenders_club_logo_only}
              fixed
            />
          </View>
        </View>

        <Text style={[styles.title, styles.extraMargin]}>{tenderTitle}</Text>
        {/* <Text style={styles.author}>{`${t("Generated for")} ${email}`}</Text> */}

        {returnPreparedData().map((preparedObj, idx) => {
          return preparedObj.twoColumns ? (
            <View
              key={`${idx}`}
              style={[styles.doubleColumnView, styles.justifyBetween]}
              wrap={false}
            >
              <View
                key={`${idx}_0`}
                style={[
                  styles.headingAndValueFlex,
                  styles.headingAndValueFlexFirst,
                  styles.headingAndValueFlexHalf,
                ]}
              >
                <Text style={styles.itemHeading}>
                  {preparedObj.values[0].field.translations[
                    i18n.language.slice(0, 2)
                  ]
                    ? preparedObj.values[0].field.translations[
                        i18n.language.slice(0, 2)
                      ].display_name
                    : preparedObj.values[0].field.translations[
                        Object.keys(preparedObj.values[0].field.translations)[0]
                      ].display_name}
                  :
                </Text>
                <Text
                  style={preparedObj.twoColumns ? styles.text : styles.text}
                >
                  {removeDoubleQuotes(preparedObj.values[0].value)}
                </Text>
              </View>
              <View
                key={`${idx}_1`}
                style={[
                  styles.headingAndValueFlex,
                  styles.headingAndValueFlexHalf,
                  styles.marginLeftAuto,
                ]}
              >
                <Text style={styles.itemHeading}>
                  {preparedObj.values[1].field.translations[
                    i18n.language.slice(0, 2)
                  ]
                    ? preparedObj.values[1].field.translations[
                        i18n.language.slice(0, 2)
                      ].display_name
                    : preparedObj.values[1].field.translations[
                        Object.keys(preparedObj.values[1].field.translations)[0]
                      ].display_name}
                  :
                </Text>
                <Text style={styles.text}>
                  {removeDoubleQuotes(preparedObj.values[1].value)}
                </Text>
              </View>
            </View>
          ) : (
            <View
              key={`${idx}`}
              style={[styles.headingAndValueFlex]}
              wrap={false}
            >
              <Text style={styles.itemHeading}>
                {preparedObj.values[0].field.translations[
                  i18n.language.slice(0, 2)
                ]
                  ? preparedObj.values[0].field.translations[
                      i18n.language.slice(0, 2)
                    ].display_name
                  : preparedObj.values[0].field.translations[
                      Object.keys(preparedObj.values[0].field.translations)[0]
                    ].display_name}
                :
              </Text>
              {preparedObj.values[0].return_type === "CSV" ? (
                <ItemsTable
                  transposed={false}
                  fieldValue={preparedObj.values[0].value}
                />
              ) : (
                <Text style={styles.text}>
                  {removeDoubleQuotes(preparedObj.values[0].value)}
                </Text>
              )}
            </View>
          );
        })}
      </Page>
    </Document>
  );
};

export const removeDoubleQuotes = (value) => {
  if (value.startsWith('"') && value.endsWith('"')) {
    return value.substring(1, value.length - 1);
  }

  return value;
};

const styles = StyleSheet.create({
  page: {
    width: "100%",
    fontSize: 11,
    flexDirection: "column",
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  summaryNameAtTop: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "left",
    color: "grey",
    fontFamily: "Montserrat",
  },
  dateAtTop: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "right",
    color: "grey",
    fontFamily: "Montserrat",
  },
  marginLeftAuto: {
    marginLeft: "auto",
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    fontFamily: "Oswald",
  },
  author: {
    fontSize: 12,
    textAlign: "center",
    marginBottom: 40,
  },
  itemHeading: {
    fontSize: 16,
    marginLeft: 12,
    marginTop: 16,
    marginBottom: 2,
    fontFamily: "Oswald",
  },
  extraMargin: {
    marginTop: 16,
    marginBottom: 16,
  },
  doubleColumnView: {
    display: "flex",
    width: "100%",
    marginBottom: 12,
    fontSize: 10,
    textAlign: "justify",
    fontFamily: "Montserrat",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  justifyBetween: {
    justifyContent: "space-between",
  },
  headingAndValueFlex: {
    display: "flex",
    margin: 0,
    fontSize: 10,
    textAlign: "justify",
    fontFamily: "Montserrat",
    flexDirection: "column",
  },
  headingAndValueFlexHalf: {
    width: "50%",
  },
  headingAndValueFlexFirst: {
    marginRight: 12,
  },
  text: {
    marginLeft: 12,
    marginTop: 0,
    marginBottom: 0,
    fontSize: 11,
    lineHeight: 1.3,
    textAlign: "justify",
    fontFamily: "Montserrat",
  },
  image: {
    marginTop: 25,
    marginBottom: 50,
    marginHorizontal: 100,
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});
