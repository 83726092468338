import { createWithEqualityFn } from "zustand/traditional";
import { shallow } from "zustand/shallow";
// import { immer } from "zustand/middleware/immer";
import { persist, createJSONStorage } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import { mergeDeepLeft } from "ramda";
import devDebug from "../Helpers/debug";
import i18n from "i18next";
import all_cpv_trees from "../Assets/CPVs/all_cpv_trees.json";
import { get, set, del } from "idb-keyval"; // can use anything: IndexedDB, Ionic Storage, etc.
import { useTabStore } from "./tabStore";
import { useMapsStore } from "./mapsStore";
import { useCPVStore } from "./cpvStore";
import cloneDeep from "lodash/cloneDeep";

const storage = {
  getItem: async (name) => {
    //devDebug(name, "has been retrieved");
    return (await get(name)) || null;
  },
  setItem: async (name, value) => {
    //devDebug(name, "with value", value, "has been saved");
    await set(name, value);
  },
  removeItem: async (name) => {
    //devDebug(name, "has been deleted");
    await del(name);
  },
};

const starterStrategy = {
  name: i18n.t("Strategy"),
  email: "",
  nuts: [],
  cpvs: [],
  mepa_only: false,
  keywords: "",
  strategy_search_choice: "CPV",
  cpv_count: [],
};

// define the initial state
const initialState = {
  _hasHydrated: false,
  user: {
    usercolumnview: {
      platform_id: true,
      title: true,
      estimated_total_value: true,
      closing_date: true,
      cpv: true,
      tender_national_id: false,
      publication_date: false,
      tender_url: false,
      tendering_authority: false,
      currency: false,
      procedure_title: false,
      procedure_type: false,
      short_description: false,
      award_criteria: false,
      soa_categories: false,
      nut: true,
    },
  },
  userLoaded: false,
  reloadCPVs: false,
  editableStrategies: { tender_strategy: {}, edit_user: {} },
  success: false,
  metric: "total_price",
  getError: false,
  submitErrors: { all: "" },
  errType: { all: "" },
  tutorialEnabled: true,
  cpvTrees: { all: all_cpv_trees[i18n.language.slice(0, 2)] },
  CPVStage: "loading",
  strategiesStatus: "loading",
  unsavedWarning: 0,
  addStrategyOnLoad: false,
};

export const useTenderStrategyStore = createWithEqualityFn(
  persist(
    immer((set, get) => ({
      ...initialState,
      setHasHydrated: (state) => {
        set({
          _hasHydrated: state,
        });
      },
      // ------------------- USER --------------
      setUser: (payload) =>
        set((state) => {
          state.user = payload;
        }),
      updatedColumn: (column, checked) =>
        set((state) => {
          state.user.usercolumnview[column] = checked;
        }),
      setUserLoaded: (payload) =>
        set((state) => {
          state.userLoaded = payload;
        }),
      // ------------------- EXISTING TENDER STRATEGIES -----------------------
      setStrategiesStatus: (payload) =>
        set((state) => {
          state.strategiesStatus = payload;
        }),
      setAddStrategyOnLoad: (addStrategyOnLoad) =>
        set((state) => {
          state.addStrategyOnLoad = addStrategyOnLoad;
        }),
      setEditableStrategies: (existingStrategies, section) =>
        set((state) => {
          const strategiesToKeep = {};
          for (let key of Object.keys(state.editableStrategies[section])) {
            if (key.includes("_new")) {
              strategiesToKeep[state.editableStrategies[section][key].id] = state.editableStrategies[section][key];
            }
          }

          state.editableStrategies[section] = {
            ...existingStrategies,
            ...strategiesToKeep,
          };

          if (Object.keys(state.editableStrategies[section]).length === 0) {
            devDebug(`setEditableStrategies -> all strategies is empty`);
            const id = Math.random() + "_new";
            state.editableStrategies[section][id] = {
              ...starterStrategy,
              id: id,
              name: i18n.t("New Strategy"),
              addedAsFiller: false,
            };
            useMapsStore.getState().addDefaultMapData(section, id);
            useMapsStore.getState().addDefaultMapState(section, id);
            useCPVStore.getState().addDefaultCPVData(section, id);
            useTabStore.getState().setTabID(section, id);
            devDebug(`setEditableStrategies -> all strategies is: `, cloneDeep(state.editableStrategies));
            state.strategiesStatus = "loaded";
          } else {
            state.strategiesStatus = "loaded";
          }
        }),
      addStrategy: (section, addedAsFiller) =>
        set((state) => {
          devDebug(`addStrategy -> section: ${section}, addedAsFiller: ${addedAsFiller}`);
          const id = Math.random() + "_new";
          state.editableStrategies[section][id] = {
            ...starterStrategy,
            id: id,
            name: i18n.t("New Strategy"),
            addedAsFiller: addedAsFiller,
          };
          useMapsStore.getState().addDefaultMapData(section, id);
          useMapsStore.getState().addDefaultMapState(section, id);
          useCPVStore.getState().addDefaultCPVData(section, id);
          useTabStore.getState().setTabID(section, id);
          if (section === "tender_strategy") {
            state.unsavedWarning = 1;
          }
          devDebug(`addStrategy -> all strategies is: `, cloneDeep(state.editableStrategies));
        }),
      deleteUnsavedStrategy: (section, id) =>
        set((state) => {
          delete state.editableStrategies[section][id];
          let unsaved = false;
          if (section === "tender_strategy") {
            for (let strat of Object.keys(state.editableStrategies[section])) {
              devDebug(`This is a key: ${strat}`);
              if (strat.includes("_new")) {
                unsaved = true;
              }
            }
            if (unsaved) {
              state.unsavedWarning = 1;
            } else {
              state.unsavedWarning = 0;
            }
          }
          devDebug(`were there unsaved strategies? ${unsaved}`);
        }),
      cleanUpFillerStrategies: () =>
        set((state) => {
          devDebug(`tenderStrategyStore -> cleaning up filler strategies`);
          const currentTab = useTabStore.getState().currentTab["edit_user"];
          for (let key of Object.keys(state.editableStrategies["edit_user"])) {
            devDebug(`this is a key: ${key} and currentTab: ${currentTab}`);
            if (state.editableStrategies["edit_user"][key].addedAsFiller) {
              devDebug(`this is a filler strategy`);
              if (key !== currentTab) {
                devDebug(`key did not equal the current tab, deleting ${key}`);
                delete state.editableStrategies["edit_user"][key];
              }
            }
          }
        }),

      setName: (payload, section, id) =>
        set((state) => {
          state.editableStrategies[section][id].name = payload;
        }),
      setEmail: (payload, section, id) =>
        set((state) => {
          state.editableStrategies[section][id].email = payload;
        }),
      setMepaOption: (payload, section, id) =>
        set((state) => {
          state.editableStrategies[section][id].mepa_only = payload;
        }),
      setSearchChoice: (payload, section, id) =>
        set((state) => {
          state.editableStrategies[section][id].strategy_search_choice = payload;
        }),
      setKeywords: (payload, section, id) =>
        set((state) => {
          state.editableStrategies[section][id].keywords = payload;
        }),
      addStrategyFromServer: (payload, section) =>
        set((state) => {
          state.editableStrategies[section][payload.id] = payload;
        }),
      setSubmitError: (err, section, id) =>
        set((state) => {
          state.submitErrors[id] = err;
        }),
      setErrType: (err, section, id) =>
        set((state) => {
          state.errType[id] = err;
        }),

      // ------------------- UPDATE NUTS AND CPV DATA -----------------------
      setCPVs: (payload, section, id) =>
        set((state) => {
          state.editableStrategies[section][id].cpvs = payload;
          let cpvobj = {};
          for (let cpv of payload) {
            cpvobj[cpv] = { checked: true, partialChecked: false };
          }
          state.editableStrategies[section][id].checkboxCPVs = cpvobj;
          let secondCPVObj = {};
          for (let cpv of payload) {
            secondCPVObj[cpv] = true;
          }
          state.editableStrategies[section][id].normalCPVs = secondCPVObj;
        }),
      setNUTS: (payload, section, id) =>
        set((state) => {
          state.editableStrategies[section][id].nuts = payload;
        }),
      // ------------------- OTHER -----------------------
      setTutorialEnabled: (payload) => set(() => ({ tutorialEnabled: payload })),
      resetState: () => {
        set(initialState);
      },
    })),
    {
      partialize: (state) =>
        Object.fromEntries(
          Object.entries(state).filter(
            ([key]) =>
              ![
                "_hasHydrated",
                "strategiesStatus",
                "user",
                "userLoaded",
                "animatedOnce",
                "dashboardDataLoading",
              ].includes(key)
          )
        ),
      name: "tenderStrategy",
      storage: createJSONStorage(() => storage),
      version: process.env.REACT_APP_VERSION,
      merge: (persistedState, currentState) => mergeDeepLeft(persistedState, currentState),
      onRehydrateStorage: (state) => {
        // devDebug("tenderStrategyStore -> hydration starts, state: ", state);
        return (state, error) => {
          if (error) {
            // devDebug(
            //   "tenderStrategyStore -> an error happened during hydration",
            //   error
            // );
          } else {
            // devDebug("tenderStrategyStore -> hydration finished, state: ", state);
            state.setHasHydrated(true);
          }
        };
      },
    }
  ),
  shallow
);
