import React from "react";
import {
  Container,
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  CardBody,
  Card,
  Button,
} from "reactstrap";
import Icon from "@mdi/react";
import { mdiChevronDown, mdiChevronRight } from "@mdi/js";
import { useTranslation } from "react-i18next";
import BreadCrumb from "../../Components/breadCrumb";
import Helmet from "react-helmet";
//import devDebug from "../../Helpers/debug";
import { useAuth } from "../../Services/auth";
import { useVademecumStore } from "../../Stores/vademecumStore";
import VademecumTable from "./VademecumTable";
import { useViewEditTenderStore } from "../../Stores/viewEditTender";
import SearchBox from "./searchBox";
import CreatedAtDateRangeBox from "./createdAtBox";
import CompletedAtDateRangeBox from "./completedAtBox";
import SummaryResultModal from "../View Tender/SummaryTab/modals/SummaryResultsModal/summaryResultModal";
import SummaryTemplateModal from "../View Tender/SummaryTab/modals/summaryTemplateModal";
import { getFields, getTemplates } from "../View Tender/2_UtilityFunctions/viewTenderUtilities";
import FieldSelectionWizardModal from "../View Tender/SummaryTab/modals/fieldSelectionWizardModal";

export default function VademecumPage() {
  const auth = useAuth();
  const apiService = auth.apiService;
  const [btnsecondary1, setBtnsecondary1] = React.useState(false);
  const [summaryIDToGET, setSummaryTemplateModalVisible, templatesStatus, fieldsStatus, summaryResultModalVisible] =
    useViewEditTenderStore((state) => [
      state.summaryIDToGET,
      state.setSummaryTemplateModalVisible,
      state.templatesStatus,
      state.fieldsStatus,
      state.summaryResultModalVisible,
    ]);

  const [
    setReRenderInputs,
    resetState,
    page,
    setPage,
    search,
    pageSize,
    setPageSize,
    totalPages,
    setTotalPages,
    pageInput,
    setPageInput,
    setVademecums,
    setVademecumStatus,
  ] = useVademecumStore((state) => [
    state.setReRenderInputs,
    state.resetState,
    state.page,
    state.setPage,
    state.search,
    state.pageSize,
    state.setPageSize,
    state.totalPages,
    state.setTotalPages,
    state.pageInput,
    state.setPageInput,
    state.setVademecums,
    state.setVademecumStatus,
  ]);

  const { t } = useTranslation();
  const [hiddenColumns] = React.useState([]);

  //todo: columnfilters don't update in url. need to update.
  const handlePageClick = (page) => {
    setPage(page);
    pageChange(-1, page);
  };

  const pageInputElem = () => {
    if (!pageInput) {
      return (
        <p
          className="th-grey-text mb-0 align-self-center pagination_para list-user-page-number"
          onClick={() => {
            setPageInput(!pageInput);
          }}
        >
          {page}
        </p>
      );
    } else {
      return (
        <input
          className="pagination_input th-grey-text"
          type="number"
          defaultValue={page}
          autoFocus
          onBlur={(e) => {
            if (!/[0-9]/.test(e.target.value)) {
              e.target.value = 1;
            }
            if (e.target.value < 0) {
              e.target.value = 1;
            }
            if (e.target.value > totalPages) {
              e.target.value = totalPages;
            }
            setPageInput(!pageInput);
            pageChange(-1, parseInt(e.target.value));
            setPage(parseInt(e.target.value));
          }}
        />
      );
    }
  };

  const pageChange = (page_size, page_num) => {
    if (page_size !== -1) {
      setPageSize(page_size);
      setPage(1);
    } else {
      setPage(page_num);
    }
  };

  const getVademecumsSuccess = (resp) => {
    setVademecums(resp.results);
    setTotalPages(resp.total_pages);
    setVademecumStatus("loaded");
  };
  const getVademecumsError = (err) => {
    if (err?.response?.status === 404) {
      setPage(1);
      getTableData();
    }
    setVademecumStatus("error");
  };

  const getTableData = (controller) => {
    setReRenderInputs();
    setVademecumStatus("refreshing");
    if (!controller) {
      controller = new AbortController();
    }

    const state = useVademecumStore.getState();

    apiService.axiosGET(
      `vademecum/vademecums/?page=${state.page}&page_size=${state.pageSize}&ordering=${state.ordering.url}&state=${state.state}&user_email=${state.user_email}&created_at_after=${state.created_at_after}&created_at_before=${state.created_at_before}&completed_at_after=${state.completed_at_after}&completed_at_before=${state.completed_at_before}`, //endpoint
      controller.signal, //signal
      getVademecumsSuccess, //successFn
      auth.newErrorHandler, //error AlerterFn
      "Error retrieving vademecums", //error message
      getVademecumsError, //errorFn (gets error back)
      { section: "Vademecum Page -> getTableData", suppressError: 404 } //data (gets handed back to success fn)
    );
  };

  React.useEffect(() => {
    const controller = new AbortController();
    getTableData(controller);

    return () => {
      controller.abort();
    };
  }, [t, page, pageSize, search]);

  React.useEffect(() => {
    const controller = new AbortController();
    if (auth.apiService) {
      getFields(controller, auth);
      getTemplates(controller, false, auth);
    }
    return () => {
      controller.abort();
    };
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <title>{t("Vademecum") + " | " + t("App Title")}</title>
      </Helmet>
      <Container fluid>
        <BreadCrumb title={t("Home")} link={"/"} breadcrumbItem={t("Vademecum")} />
        <Row className="justify-content-center">
          <Col lg={6}>
            <div className="text-center tc-text-muted mb-5">
              <h4>{t("Vademecum")}</h4>
              <p className="th-grey-text">
                {t("Here you can see all summaries requested under vademecum by all users")}
              </p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                <div className="th-tender-table-filter-control-grid tc-text-muted">
                  <SearchBox />
                  <CreatedAtDateRangeBox />
                  <CompletedAtDateRangeBox />
                </div>
                <div className="d-flex flex-column flex-sm-row tc-flex-gap-10px align-items-center w-full mt-2">
                  <Button className="tc-full-width-btn-576px" color="primary" onClick={() => getTableData()}>
                    {t("Apply Filters")}
                  </Button>
                  <Button
                    className="tc-full-width-btn-576px"
                    onClick={() => {
                      resetState();
                      getTableData();
                    }}
                  >
                    {t("Reset Filters")}
                  </Button>
                  <Button
                    className={`${
                      templatesStatus === "loaded" && fieldsStatus === "loaded"
                        ? "tc-full-width-btn-576px"
                        : "tc-full-width-btn-576px disabled"
                    }`}
                    onClick={() => setSummaryTemplateModalVisible(true)}
                  >
                    {t("Templates")}
                  </Button>
                </div>
              </CardBody>
            </Card>
            <VademecumTable getTableData={getTableData} hideColumns={hiddenColumns} />
          </Col>
        </Row>
        <Row>
          <div className="d-flex">
            <Dropdown isOpen={btnsecondary1} toggle={() => setBtnsecondary1(!btnsecondary1)} className="ms-auto">
              <DropdownToggle tag="button" className="btn btn-secondary number_of_table_rows_button">
                {t("# Items")}
                <Icon path={mdiChevronDown} className="" title={t("Number of Items")} size={0.8} />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem className="list-user-pagesize-option" onClick={() => pageChange(10, -1)}>
                  10
                </DropdownItem>
                <DropdownItem className="list-user-pagesize-option" onClick={() => pageChange(25, -1)}>
                  25
                </DropdownItem>
                <DropdownItem className="list-user-pagesize-option" onClick={() => pageChange(50, -1)}>
                  50
                </DropdownItem>
                <DropdownItem className="list-user-pagesize-option" onClick={() => pageChange(100, -1)}>
                  100
                </DropdownItem>
                <DropdownItem className="list-user-pagesize-option" onClick={() => pageChange(200, -1)}>
                  200
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
            <div className="d-flex flex-row justify-content-center align-items-center">
              <div className={page === 1 ? "th-pagination-chevron-disabled" : null}>
                <div className="cursor-pointer previous_page_button" onClick={() => handlePageClick(page - 1)}>
                  <Icon path={mdiChevronRight} title={t("Previous Page")} horizontal size={1} />
                </div>
              </div>

              <div className="d-flex page_number_display">
                {pageInputElem()}
                <p className="th-grey-text mb-0 align-self-center tender_table_num_pages">/ {totalPages}</p>
              </div>

              <div className={page === totalPages ? "th-pagination-chevron-disabled" : null}>
                <div className="cursor-pointer next_page_button" onClick={() => handlePageClick(page + 1)}>
                  <Icon path={mdiChevronRight} title={t("Next Page")} size={1} />
                </div>
              </div>
            </div>
          </div>
        </Row>
      </Container>
      {summaryIDToGET ? (
        <SummaryResultModal key={`${summaryIDToGET}_${summaryResultModalVisible}`} isAdminRoute={true} />
      ) : null}
      {templatesStatus === "loaded" && fieldsStatus === "loaded" ? (
        <SummaryTemplateModal isAdminRoute={true} getSummaries={() => {}} submitCapability={false} />
      ) : null}
      {templatesStatus === "loaded" && fieldsStatus === "loaded" ? (
        <FieldSelectionWizardModal submitCapability={false} getSummaries={() => {}} />
      ) : null}
    </React.Fragment>
  );
}
