import React from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import Layout from "./Layout/layout";
import {
  AuthProvider,
  RequireAuth,
  RequireAdmin,
  removeAllLocalState,
  clearCookies,
} from "./Services/auth";
import "./SCSS/theme.scss";
import "leaflet/dist/leaflet.css";
import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css";
import NoMatch from "./Components/noMatch";
import { StrategyProvider } from "./Services/strategies/strategyService";
import ScreenSpinner from "./Components/ScreenSpinner";
import { useTabStore } from "./Stores/tabStore";
import { useTenderStrategyStore } from "./Stores/tenderStrategyStore";
import devDebug from "./Helpers/debug";
import { ErrorBoundary } from "react-error-boundary";
import { MainAppErrorComponent } from "./Components/errorComponent";
import i18n from "./i18n";
import MyTendersErrorBoundary from "./Pages/Protected - My Tenders/MyTendersErrorBoundary";
import VademecumErrorBoundary from "./Pages/Admin - Vademecum/VademecumErrorBoundary";

const Logout = () => {
  removeAllLocalState(false); //false means auth data also gets deleted
  clearCookies();
  return <Navigate to="/" />;
};

const AddTenderFileUploadPage = React.lazy(() => import("./Pages/Admin - Add Tender File Upload/addTenderFileUpload"));

const LoginPage = React.lazy(() => import("./Pages/Public - Login/login"));
const TenderStrategy = React.lazy(() =>
  import("./Pages/Protected - Tender Strategy/tenderStrategy")
);
const RegisterErrorBoundary = React.lazy(() =>
  import("./Pages/Public - Register/RegisterErrorBoundary")
);
const Dashboard = React.lazy(() =>
  import("./Pages/Public - Dashboard/dashboard")
);
const TenderAwardsMain = React.lazy(() =>
  import("./Pages/Protected - Tender Awards/TenderAwardsMain")
);
const ForwardEmailLink = React.lazy(() =>
  import("./Pages/Public - Forwarders/forwardEmailLink")
);
const UserPreferences = React.lazy(() =>
  import("./Pages/Protected - User Preferences/userPreferences")
);
const Pricing = React.lazy(() => import("./Pages/Public - Pricing/pricing"));
const Contact = React.lazy(() => import("./Pages/Public - Contact/contact"));
const TenderSearch = React.lazy(() =>
  import("./Pages/Protected - Tender Search/tenderSearchInit")
);
const TenderAwardsSearch = React.lazy(() =>
  import("./Pages/Protected - Tender Awards Search/tenderAwardsSearchInit")
);
const CompaniesSearchErrorBoundary = React.lazy(() =>
  import("./Pages/Protected - Companies Search/CompaniesSearchErrorBoundary")
);
const ForgotPasswordPage = React.lazy(() =>
  import("./Pages/Public - Forgot Password/forgotPassword")
);
const CompletePwdReset = React.lazy(() =>
  import("./Pages/Public - Complete Password Reset/completePwdReset")
);
// const ListAdminTenders = React.lazy(() =>
//   import("./Pages/Admin - List Tenders/listAdminTenders")
// );
const ListUsersErrorBoundary = React.lazy(() =>
  import("./Pages/Admin - List Users/ListUsersErrorBoundary")
);
const EditUserErrorBoundary = React.lazy(() =>
  import("./Pages/Admin - Edit User/EditUserErrorBoundary")
);
const ViewTenderPage = React.lazy(() =>
  import("./Pages/View Tender/viewTenderPage")
);
const CPVStats = React.lazy(() =>
  import("./Pages/Public - CPV Stats/CPVStats")
);

const useScript = () => {
  React.useEffect(() => {
    let script = document.createElement("script");
    let feedbackScript = document.createElement("script");

    if (localStorage.getItem("i18nextLng")) {
      i18n.changeLanguage(localStorage.getItem("i18nextLng"));
    }
    script.setAttribute("type", "text/javascript");
    script.setAttribute("src", "https://s2.webapi.ai/chat-widget/uniq-chat.js");

    document.body.appendChild(script);

    window.ailabs_user_info = {
      client: "ufficioappalti",
      header: "Tenders.club Chat",
      welcome_message: "Welcome to the chat 👋",
    };

    window.setTimeout(() => {
      window.AILabsChatStart();
    }, 2000);

    window.fbl_2020_Widget_Config = {
      position: "left",
      color: "light",
      link: "https://feedback.link/tendersclub",
      brandHex: "#2D2F41",
      frequency: "t",
    };
    feedbackScript.setAttribute(
      "src",
      "https://feedback.link/web_widget/feedbackbutton?id=FYC7vG8aTDFv7zafCG6bEqJ0sjHyzaGN2WWG9s1680867759"
    );
    document.body.appendChild(feedbackScript);

    return () => {
      document.body.removeChild(script);
      document.body.removeChild(feedbackScript);
    };
  }, []);
};

export default function App() {
  const location = useLocation();
  const [setTabID] = useTabStore((state) => [state.setTabID]);
  const [setUserLoaded] = useTenderStrategyStore((state) => [
    state.setUserLoaded,
  ]);

  React.useEffect(() => {
    // execute on location change
    devDebug(`App -> location is: ${location.pathname}`);

    setUserLoaded(false);
    setTabID("edit_user", null);
    //edit user is always reset, as admins will load different
    //users frequently, so needs resetting just about always
  }, [location]);

  return (
    <ErrorBoundary
      FallbackComponent={MainAppErrorComponent}
      onReset={(details) => {
        devDebug("App -> error boundary reset", details);
        removeAllLocalState(true); //true is the 'notAuth' param
      }}
    >
      <React.Fragment key={location}>{useScript()}</React.Fragment>
      <Routes>
        <Route path="/logout" element={<Logout />} />
      </Routes>

      <AuthProvider>
        <StrategyProvider>
          <Routes>
            <Route path="*" element={<NoMatch />} />
            <Route element={<Layout />}>
              {/* ------------- BEGINNING OF ROUTES WHICH INCLUDE TABS ---------------- */}

              <Route
                path="/dashboard"
                element={
                  <React.Suspense
                    fallback={<ScreenSpinner copy="Loading Dashboard..." />}
                  >
                    <Dashboard />
                  </React.Suspense>
                }
              />
              <Route
                path="/tender_awards"
                element={
                  <RequireAuth>
                    <React.Suspense
                      fallback={
                        <ScreenSpinner copy="Loading Tender Awards..." />
                      }
                    >
                      <TenderAwardsMain section="tender_awards" />
                    </React.Suspense>
                  </RequireAuth>
                }
              />
              <Route
                path="/awards_search"
                element={
                  <RequireAuth>
                    <React.Suspense
                      fallback={
                        <ScreenSpinner copy="Loading Awards Search..." />
                      }
                    >
                      <TenderAwardsSearch section="awards_search" />
                    </React.Suspense>
                  </RequireAuth>
                }
              />
              <Route
                path="/companies_search"
                element={
                  <RequireAuth>
                    <React.Suspense
                      fallback={
                        <ScreenSpinner copy="Loading Companies Search..." />
                      }
                    >
                      <CompaniesSearchErrorBoundary section="companies_search" />
                    </React.Suspense>
                  </RequireAuth>
                }
              />
              <Route
                path="/search"
                element={
                  <RequireAuth>
                    <React.Suspense
                      fallback={
                        <ScreenSpinner copy="Loading Tender Search..." />
                      }
                    >
                      <TenderSearch admin={false} />
                    </React.Suspense>
                  </RequireAuth>
                }
              />
              <Route
                path="/tender_strategy"
                element={
                  <RequireAuth>
                    <React.Suspense
                      fallback={
                        <ScreenSpinner copy="Loading Tender Strategies..." />
                      }
                    >
                      <TenderStrategy section="tender_strategy" />
                    </React.Suspense>
                  </RequireAuth>
                }
              />
              <Route
                path="/admin/edit_user/:id"
                element={
                  <RequireAdmin>
                    <React.Suspense
                      fallback={<ScreenSpinner copy="Loading Edit User..." />}
                    >
                      <EditUserErrorBoundary mode="edit" section="edit_user" />
                    </React.Suspense>
                  </RequireAdmin>
                }
              />
              {/* ------------- END OF ROUTES WHICH INCLUDE TABS ---------------- */}
              <Route
                path="/login"
                element={
                  <React.Suspense
                    fallback={<ScreenSpinner copy="Loading Login..." />}
                  >
                    <LoginPage />
                  </React.Suspense>
                }
              />
              <Route
                path="/cpv_stats"
                element={
                  <React.Suspense
                    fallback={
                      <ScreenSpinner copy="Loading CPV Statistics..." />
                    }
                  >
                    <CPVStats />
                  </React.Suspense>
                }
              />
              <Route
                path="/forgot-password"
                element={
                  <React.Suspense
                    fallback={
                      <ScreenSpinner copy="Loading Password Reset..." />
                    }
                  >
                    <ForgotPasswordPage />
                  </React.Suspense>
                }
              />
              <Route
                path="/register"
                element={
                  <React.Suspense
                    fallback={<ScreenSpinner copy="Loading Registration..." />}
                  >
                    <RegisterErrorBoundary />
                  </React.Suspense>
                }
              />
              <Route
                path="/pricing"
                element={
                  <React.Suspense
                    fallback={<ScreenSpinner copy="Loading Pricing..." />}
                  >
                    <Pricing />
                  </React.Suspense>
                }
              />
              <Route
                path="/contact"
                element={
                  <React.Suspense
                    fallback={<ScreenSpinner copy="Loading Contact..." />}
                  >
                    <Contact />
                  </React.Suspense>
                }
              />
              <Route
                path="/guest/registration/account-confirm-email/:token"
                element={
                  <React.Suspense
                    fallback={<ScreenSpinner copy="Confirming Email..." />}
                  >
                    <ForwardEmailLink />
                  </React.Suspense>
                }
              />
              <Route
                path="/password-reset-confirm/:uid/:token"
                element={
                  <React.Suspense
                    fallback={
                      <ScreenSpinner copy="Loading Password Reset..." />
                    }
                  >
                    <CompletePwdReset />
                  </React.Suspense>
                }
              />
              <Route
                path="/user_preferences"
                element={
                  <RequireAuth>
                    <React.Suspense
                      fallback={<ScreenSpinner copy="Loading Settings..." />}
                    >
                      <UserPreferences />
                    </React.Suspense>
                  </RequireAuth>
                }
              />
              <Route
                path="/view_tender/:id"
                element={
                  <RequireAuth>
                    <React.Suspense
                      fallback={<ScreenSpinner copy="Loading Tender..." />}
                    >
                      <ViewTenderPage
                        isAdminRoute={false}
                        isPublicRoute={false}
                        section="view_tender_protected"
                      />
                    </React.Suspense>
                  </RequireAuth>
                }
              />
              <Route
                path="/viewtenderpublic/:id"
                element={
                  <React.Suspense
                    fallback={<ScreenSpinner copy="Loading Public Tender..." />}
                  >
                    <ViewTenderPage
                      isAdminRoute={false}
                      isPublicRoute={true}
                      section="view_tender_public"
                    />
                  </React.Suspense>
                }
              />
              <Route
                path="/admin/edit_tenders"
                element={
                  <RequireAdmin>
                    <React.Suspense
                      fallback={<ScreenSpinner copy="Loading Edit Tender..." />}
                    >
                      <TenderSearch admin={true} />
                    </React.Suspense>
                  </RequireAdmin>
                }
              />
              <Route
                path="/admin/list_users"
                element={
                  <RequireAdmin>
                    <React.Suspense
                      fallback={<ScreenSpinner copy="Loading User List..." />}
                    >
                      <ListUsersErrorBoundary />
                    </React.Suspense>
                  </RequireAdmin>
                }
              />
              <Route
                path="/admin/add_user"
                element={
                  <RequireAdmin>
                    <React.Suspense
                      fallback={<ScreenSpinner copy="Loading Add User..." />}
                    >
                      <EditUserErrorBoundary mode="add" section="edit_user" />
                    </React.Suspense>
                  </RequireAdmin>
                }
              />
              <Route
                path="/admin/vademecum"
                element={
                  <RequireAdmin>
                    <React.Suspense
                      fallback={<ScreenSpinner copy="Loading Vademecum..." />}
                    >
                      <VademecumErrorBoundary />
                    </React.Suspense>
                  </RequireAdmin>
                }
              />
              <Route
                path="/my_tenders"
                element={
                  <React.Suspense
                    fallback={<ScreenSpinner copy="Loading My Tenders..." />}
                  >
                    <MyTendersErrorBoundary />
                  </React.Suspense>
                }
              />
              <Route
                path="/admin/view_tender/:id"
                element={
                  <RequireAdmin>
                    <React.Suspense
                      fallback={<ScreenSpinner copy="Loading Tender..." />}
                    >
                      <ViewTenderPage
                        isAdminRoute={true}
                        isPublicRoute={false}
                        section="edit_tender"
                      />
                    </React.Suspense>
                  </RequireAdmin>
                }
              />
              <Route
                path="/admin/add_tender"
                element={
                  <RequireAdmin>
                    <React.Suspense
                      fallback={<ScreenSpinner copy="Loading Tender..." />}
                    >
                      <ViewTenderPage
                        isAdminRoute={true}
                        isPublicRoute={false}
                        section="add_tender"
                      />
                    </React.Suspense>
                  </RequireAdmin>
                }
              />              
              <Route
                path="/admin/add_tender_file_upload"
                element={
                  <RequireAdmin>
                    <React.Suspense
                      fallback={<ScreenSpinner copy="Loading Add Tender File Upload Section..." />}
                    >
                      <AddTenderFileUploadPage
                        section="add_tender_file_upload"
                      />
                    </React.Suspense>
                  </RequireAdmin>
                }
              />
            </Route>
          </Routes>
        </StrategyProvider>
      </AuthProvider>
    </ErrorBoundary>
  );
}
