import React from "react";
import TendersClub_Logo_White from "../Assets/Images/TendersClub_Logo_White.webp";
import TendersClub_Logo_Blue from "../Assets/Images/TendersClub_Logo_Blue.webp";
import TenderClub_small_logo_white from "../Assets/Images/TenderClub_small_logo_white.webp";
import TenderClub_small_logo_dark from "../Assets/Images/TenderClub_small_logo_dark.webp";
import TenderClub_pdf_white_bg from "../Assets/Images/tc-logo-blue-white-bg.png";

export default function TendersClubLogo({ size, colour }) {
  if (size === "small" && colour === "light") {
    return (
      <img
        alt="Tenders Club Logo"
        className=""
        src={TenderClub_small_logo_white}
      ></img>
    );
  } else if (size === "small" && colour === "dark") {
    return (
      <img
        alt="Tenders Club Logo"
        className=""
        src={TenderClub_small_logo_dark}
      ></img>
    );
  } else if (size === "normal" && colour === "light") {
    return (
      <img
        alt="Tenders Club Logo"
        className="th-sidebar-logo-position"
        src={TendersClub_Logo_White}
      ></img>
    );
  } else if (size === "normal" && colour === "dark") {
    return (
      <img
        alt="Tenders Club Logo"
        className="th-sidebar-logo-position"
        src={TendersClub_Logo_Blue}
      ></img>
    );
  } else if (size === "pdf" && colour === "white_bg") {
    return (
      <img
        alt="Tenders Club Logo"
        className="th-sidebar-logo-position"
        src={TenderClub_pdf_white_bg}
      ></img>
    );
  }
}
