import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useViewEditTenderStore } from "../../../../../Stores/viewEditTender";
import { useAuth } from "../../../../../Services/auth";
import { createXLSXData } from "./summaryLotsTable";
import { useVademecumStore } from "../../../../../Stores/vademecumStore";
import { MemoizedEmailUserModal } from "../emailUserModal";
import TenderTitleComponent from "./Components/tenderTitleComponent";
import StatusSelect from "./Components/statusSelect";
import FieldValueLists from "./Components/fieldValueLists";
import SummaryModalFooter from "./Components/summaryModalFooter";
import { SummaryPDFTableStyle } from "../PDF/SummaryPDFTableStyle";
import i18n from "../../../../../i18n";
import { usePDF } from "@react-pdf/renderer";
import { getSummary, getSummaryFieldValues } from "./Utility/utility";
import devDebug from "../../../../../Helpers/debug";
import { useComponentsStore } from "../../../../../Stores/componentsStore";
import dayjs from "dayjs";
import QueryHistoryItemLogModal from "../QueryHistoryItemLogModal/QueryHistoryItemLogModal";
import { loadTender } from "../../../2_UtilityFunctions/viewTenderUtilities";

export const ReturnPDF = () => {
  const summaryFieldValues = useViewEditTenderStore.getState().summaryFieldValues;
  const textValues = summaryFieldValues.filter((item) => item.return_type === "TEXT");
  const reorderedValues = [...textValues, ...summaryFieldValues.filter((item) => item.return_type === "CSV")];

  const [instance, updateInstance] = usePDF({
    document: (
      <SummaryPDFTableStyle
        key={JSON.stringify(reorderedValues)}
        tenderTitle={returnTenderTitle(useViewEditTenderStore.getState().tender, i18n.language.slice(0, 2))}
        textValues={textValues}
        reorderedValues={reorderedValues}
      />
    ),
  });

  useVademecumStore.getState().setInstanceAndUpdateInstance(instance, updateInstance);

  return <></>;
};

export default function SummaryResultModal({ isAdminRoute }) {
  const auth = useAuth();
  const { t, i18n } = useTranslation();
  const [adminSwitch, setAdminSwitch] = React.useState(isAdminRoute);

  const [
    summaryResultModalVisible,
    setSummaryResultModalVisible,
    summaryIDToGET,
    summaryNameToGET,
    summary,
    summaryStatus,
    setSummaryStatus,
    summaryError,
    setSummaryError,
    setSummaryFieldValuesStatus,
    setSummaryFieldValuesError,
    summaryFieldValues,
    summaryFieldValuesStatus,
    summaryFieldValuesError,
    setPDFDelay,
    tenderStatus,
    tender,
  ] = useViewEditTenderStore((state) => [
    state.summaryResultModalVisible,
    state.setSummaryResultModalVisible,
    state.summaryIDToGET,
    state.summaryNameToGET,
    state.summary,
    state.summaryStatus,
    state.setSummaryStatus,
    state.summaryError,
    state.setSummaryError,
    state.setSummaryFieldValuesStatus,
    state.setSummaryFieldValuesError,
    state.summaryFieldValues,
    state.summaryFieldValuesStatus,
    state.summaryFieldValuesError,
    state.setPDFDelay,
    state.tenderStatus,
    state.tender,
  ]);
  const [instance, refreshpdf, queryHistoryItemLogModalVisible] = useVademecumStore((state) => [
    state.instance,
    state.refreshpdf,
    state.queryHistoryItemLogModalVisible,
  ]);

  React.useEffect(() => {
    if (summaryFieldValuesStatus === "loaded" && summaryStatus === "loaded") {
      const deduplicateCallTime =
        useComponentsStore.getState().deduplicateCallTime?.["summaryResultsModal_useEffect"] || 0;
      const setDeduplicateCallTime = useComponentsStore.getState().setDeduplicateCallTime;
      if (dayjs().valueOf() - deduplicateCallTime > 100) {
        devDebug("Creating XLSX Data");
        setDeduplicateCallTime("summaryResultsModal_useEffect", dayjs().valueOf());
        createXLSXData();
      }
    }
  }, [summaryFieldValues, summaryResultModalVisible, summaryIDToGET, summary]);

  //TODO: work out why the below is needed. PDF download link crashes when switching
  //      between some summaries without this in place

  React.useEffect(() => {
    const controller = new AbortController();
    getSummary(controller, auth);
    getSummaryFieldValues(controller, auth);
    setTimeout(() => {
      setPDFDelay(false);
    }, 3000);
    return () => {};
  }, []);

  React.useEffect(() => {
    if (summaryStatus === "loaded") {
      loadTender(auth, summary.tender);
    }

    return () => {};
  }, [summaryStatus]);

  return (
    <Modal
      isOpen={summaryResultModalVisible}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="tc-screen-width-modal"
      tabIndex="-1"
      zIndex="11"
      toggle={() => {
        if (summaryResultModalVisible) {
          clearInterval(useVademecumStore.getState().regenInterval);
          useVademecumStore.getState().setRegenInterval(null);
          setSummaryStatus("loading");
          setSummaryFieldValuesStatus("loading");
          setSummaryError(null);
          setSummaryFieldValuesError(null);
        }
        setSummaryResultModalVisible(!summaryResultModalVisible);
      }}
    >
      <ModalHeader
        tag="h1"
        className="ta-modals-header"
        toggle={() => {
          setSummaryResultModalVisible(!summaryResultModalVisible);
        }}
      >
        <div className="d-flex flex-column">
          <div className="tc-modal-header">{summaryNameToGET}</div>
          <TenderTitleComponent />
        </div>
      </ModalHeader>
      <ModalBody>
        <div className="tc-template-modal-max-height-tallest tc-text-muted">
          {summaryFieldValuesStatus === "loading" || summaryStatus === "loading" ? (
            <div className="d-flex align-items-center tc-flex-gap-10px">
              <div className="tc-spinner-small"></div>
              <div>{t("Loading Summary...")}</div>
            </div>
          ) : null}

          <div className="d-flex flex-column tc-flex-gap-10px">
            {adminSwitch && (summaryStatus === "loaded" || summaryStatus === "saving") ? <StatusSelect /> : null}

            {summaryFieldValuesStatus === "loaded" || summaryFieldValuesStatus === "saving" ? (
              <FieldValueLists adminSwitch={adminSwitch} />
            ) : null}

            {summaryFieldValuesStatus === "error" ? (
              <div className="tc-formerror">{summaryFieldValuesError}</div>
            ) : null}
            {summaryStatus === "error" ? <div className="tc-formerror">{summaryError}</div> : null}
          </div>
        </div>
        {summaryFieldValuesStatus === "loaded" &&
        (summaryStatus === "loaded" || summaryStatus === "saving") &&
        tenderStatus === "loaded" ? (
          <ReturnPDF key={refreshpdf} />
        ) : null}
      </ModalBody>
      <SummaryModalFooter adminSwitch={adminSwitch} setAdminSwitch={setAdminSwitch} />

      {auth.user.is_superuser &&
      tenderStatus === "loaded" &&
      summaryFieldValues &&
      (summaryStatus === "loaded" || summaryStatus === "saving") ? (
        <div className="d-flex tc-flex-gap-10px">
          {auth.user.email && instance ? (
            <MemoizedEmailUserModal
              key={`${summaryNameToGET}_${returnTenderTitle(tender, i18n.language.slice(0, 2))}`}
            />
          ) : null}
        </div>
      ) : null}
      {queryHistoryItemLogModalVisible ? <QueryHistoryItemLogModal editTenderOrVademecum={"vademecum"} /> : null}
    </Modal>
  );
}

export const returnTenderTitle = (tender, lang) => {
  if (tender.translations) {
    return Object.keys(tender.translations)[0]
      ? tender.translations[lang]
        ? tender.translations[lang].title
        : tender.translations?.[Object.keys(tender.translations)[0]]?.title
      : "";
  }
  return "No Data";
};
