import { useAuth } from "../../../Services/auth";
import devDebug from "../../../Helpers/debug";
import { useMyTendersStore } from "../../../Stores/myTenders";
import Icon from "@mdi/react";
import { mdiStar, mdiStarOutline } from "@mdi/js";
import { getFavourites } from "../../Protected - My Tenders/myTenderUtility";

export default function FavouriteTender({ bookmarked, id }) {
  const auth = useAuth();
  const apiService = auth.apiService;

  const [favouriteInProgressIDs, setFavouriteInProgressIDs] = useMyTendersStore((state) => [
    state.favouriteInProgressIDs,
    state.setFavouriteInProgressIDs,
  ]);

  const favouriteTenderSuccess = (resp, data) => {
    setFavouriteInProgressIDs(data.id);
    getFavourites(auth);
  };
  const favouriteTenderError = (err, data) => {
    devDebug("There was an error favouriting the tender: ", err);
    devDebug("There was an error favouriting the tender, this is data: ", data);
    setFavouriteInProgressIDs(data.id);
  };

  const favouriteTender = (id, del) => {
    const controller = new AbortController();

    let fn = apiService.axiosPOST;

    if (del) {
      fn = apiService.axiosDELETE;
    }

    fn(
      del ? `user/favorites/${id}` : `user/favorites/`, //endpoint
      controller.signal, //signal
      favouriteTenderSuccess, //successFn
      auth.newErrorHandler, //error AlerterFn
      "There was an error changing the favourite status of the tender", //error message
      favouriteTenderError, //errorFn (gets error back)
      {
        section: "Tender List Item -> Favourite -> favouriteTender",
        id: id,
        payload: { tender_id: id },
      } //data (gets handed back to success fn)
    );
  };

  return bookmarked ? (
    favouriteInProgressIDs[id] ? (
      <div className="mt-1 ms-1 tc-spinner-small "> </div>
    ) : (
      <Icon
        className="cursor-pointer tc-bookmarked-star-colour"
        path={mdiStar}
        size={1.5}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          setFavouriteInProgressIDs(id);
          favouriteTender(id, true);
        }}
      />
    )
  ) : favouriteInProgressIDs[id] ? (
    <div className="mt-1 ms-1 tc-spinner-small "> </div>
  ) : (
    <Icon
      className="cursor-pointer tc-text-muted tc-40px-icon-size me-3"
      path={mdiStarOutline}
      size={1.5}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        setFavouriteInProgressIDs(id);
        favouriteTender(id, false);
      }}
    />
  );
}
