import { createWithEqualityFn } from "zustand/traditional";
import { shallow } from "zustand/shallow";
import { immer } from "zustand/middleware/immer";

// define the initial state
const initialState = {
  cpvStatSearch: "",
  awardsDropDownOpen: false,
  showHelpVideoModal: false,
  showUnsavedPopup: false,
  showMultilanguageVideo: false,
  showUpgradePopup: false,
  showNoResultsMessage: false,
  finishedCounter: 0,
  nutsSearchFromDashboardClick: "",
  showCompanyModal: false,
  companyIDToGet: 0,
  companyNameToGet: "",
  tenderingAuthorityNameToGet: "",
  showTAModal: false,
  tenderingAuthorityIDToGet: 0,
  awardsTableConstraintType: "",
  awardsTableConstraintID: null,
  awardsTableConstraintName: "",
  isDark: false,
  input: "",
  hideListNutsSearch: true,
  applyModal: false,
  setApplyModalContent: "apply",
  deduplicateCallTime: {},
};

export const useComponentsStore = createWithEqualityFn(
  immer((set, get) => ({
    ...initialState,
    setIsDark: (payload) =>
      set((state) => {
        state.isDark = payload;
      }),

    // ------------------- UTILITY FUNCTIONS --------------
    setDeduplicateCallTime: (key, time) =>
      set((state) => {
        state.deduplicateCallTime[key] = time
      }),
    // ------------------- VIEW / EDIT TENDER --------------
    setApplyModalContent: (applyModalContent) =>
      set((state) => {
        state.applyModalContent = applyModalContent;
      }),
    setApplyModal: (applyModal) =>
      set((state) => {
        state.applyModal = applyModal;
      }),
    setInput: (payload) =>
      set((state) => {
        state.input = payload;
      }),
    setHideListNutsSearch: (payload) =>
      set((state) => {
        state.hideListNutsSearch = payload;
      }),
    helpVideoAction: (payload) =>
      set((state) => {
        state.showHelpVideoModal = payload;
      }),
    setShowCompanyModal: (payload) =>
      set((state) => {
        state.showCompanyModal = payload;
      }),
    setAwardsDropDownOpen: (payload) =>
      set((state) => {
        state.awardsDropDownOpen = payload;
      }),
    setCompanyIDToGet: (payload) =>
      set((state) => {
        state.companyIDToGet = payload;
      }),
    setCompanyNameToGet: (payload) =>
      set((state) => {
        state.companyNameToGet = payload;
      }),
    setTenderingAuthorityNameToGet: (payload) =>
      set((state) => {
        state.tenderingAuthorityNameToGet = payload;
      }),
    setShowTAModal: (payload) =>
      set((state) => {
        state.showTAModal = payload;
      }),
    setAwardsTableConstraint: (type, id, name) =>
      set((state) => {
        state.awardsTableConstraintType = type;
        state.awardsTableConstraintID = id;
        state.awardsTableConstraintName = name;
      }),
    setTenderingAuthorityIDToGet: (payload) =>
      set((state) => {
        state.tenderingAuthorityIDToGet = payload;
      }),
    setShowUnsavedPopup: (payload) =>
      set((state) => {
        state.showUnsavedPopup = payload;
      }),
    multiLanguageVideoAction: (payload) =>
      set((state) => {
        state.showMultilanguageVideo = payload;
      }),
    setShowUpgradePopup: (payload) =>
      set((state) => {
        state.showUpgradePopup = payload;
      }),
    setStatSearch: (payload) =>
      set((state) => {
        state.cpvStatSearch = payload;
      }),
    setShowNoResultsMessage: (payload) =>
      set((state) => {
        state.showNoResultsMessage = payload;
      }),
    setFinishedCounter: (payload) =>
      set((state) => {
        state.finishedCounter += 1;
      }),
    setNutsSearchFromDashboardClick: (payload) =>
      set((state) => {
        state.nutsSearchFromDashboardClick = payload;
      }),
  })),
  shallow
);
