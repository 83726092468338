import React from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../Services/auth";
import { Helmet } from "react-helmet";
import BreadCrumb from "../../Components/breadCrumb";
import ScreenSpinner from "../../Components/ScreenSpinner";
import { Col, Container, Row } from "reactstrap";
import { useMyTendersStore } from "../../Stores/myTenders";
import { getFavourites } from "./myTenderUtility";
import dayjs from "dayjs";
import { getSummaries } from "../View Tender/2_UtilityFunctions/viewTenderUtilities";
import { useViewEditTenderStore } from "../../Stores/viewEditTender";
import SummaryResultModal from "../View Tender/SummaryTab/modals/SummaryResultsModal/summaryResultModal";
import TenderListItemNormal from "../Protected - Tender Search/ScheduleView/tenderListItemNormal";
// import devDebug from "../../Helpers/debug";
// import cloneDeep from "lodash/cloneDeep";
// import dayjs from "dayjs";

export default function MyTendersPage({ section }) {
  const { t } = useTranslation();
  const auth = useAuth();
  const [relevantSummaries, setRelevantSummaries] = React.useState({});
  const [favourites, favouritesStatus] = useMyTendersStore((state) => [
    state.favourites,
    state.favouritesStatus,
  ]);
  const [summaries, summaryResultModalVisible] = useViewEditTenderStore((state) => [state.summaries, state.summaryResultModalVisible]);
  const [summaryIDToGET] = useViewEditTenderStore((state) => [
    state.summaryIDToGET,
  ]);
  React.useEffect(() => {
    let relevantSummaries = {};
    for (let summary of summaries) {
      for (let fav of favourites) {
        if (summary.tender === fav.tender_id) {
          relevantSummaries[summary.tender] = summary;
          break;
        }
      }
    }
    setRelevantSummaries(relevantSummaries);
  }, [summaries, favourites]);

  React.useEffect(() => {
    const controller = new AbortController();
    if (auth.apiService && auth.user) {
      getFavourites(auth);
      getSummaries(controller, auth, false, false); //controller, auth, isAdminRoute, specificTenderID
    }
  }, []);

  return favouritesStatus === "loaded" || favouritesStatus === "refreshing" ? (
    <React.Fragment>
      <Helmet>
        <title>{t("My Tenders") + " | " + t("App Title")}</title>
        <meta name="description" content={t("My Tenders Page Description")} />
        <meta property="og:title" content={t("My Tenders Page Title")} />
        <meta property="og:image" content={t("My Tenders Page Image")} />
      </Helmet>
      <Container fluid>
        <BreadCrumb
          title={t("Home")}
          link={"/"}
          breadcrumbItem={t("My Tenders")}
        />
        <Row className="justify-content-center">
          <Col lg={12}>
            <div className="d-flex flex-column tc-text-muted">
              <h4 className="text-center">{t("My Tenders")}</h4>
              <p className="th-grey-text text-center">
                {t("Here you can see all tenders you are currently tracking")}
              </p>
              <div className="d-flex flex-column tc-flex-gap-10px">
                {favourites
                  .filter((tender, idx) =>
                    dayjs(tender.closing_date).isAfter(dayjs())
                  )
                  .map((tender, idx) => (
                    <TenderListItemNormal
                      key={`${idx}`}
                      tender={tender}
                      bookmarked={true}
                      summary={
                        relevantSummaries[tender.tender_id]
                          ? relevantSummaries[tender.tender_id]
                          : { state: "NONE" }
                      }
                    />
                  ))}
              </div>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-center mt-5">
          <Col lg={12}>
            <div className="d-flex flex-column tc-text-muted">
              <h4 className="text-center">{t("Expired Tenders")}</h4>
              <p className="th-grey-text text-center">
                {t("The closing date of these tenders is after today")}
              </p>
              <div className="d-flex flex-column tc-flex-gap-10px">
                {favourites
                  .filter((tender, idx) =>
                    dayjs(tender.closing_date).isBefore(dayjs())
                  )
                  .map((tender, idx) => (
                    <TenderListItemNormal
                      key={`${idx}`}
                      tender={tender}
                      bookmarked={true}
                      summary={
                        relevantSummaries[tender.tender_id]
                          ? relevantSummaries[tender.tender_id]
                          : { state: "NONE" }
                      }
                    />
                  ))}
              </div>
            </div>
          </Col>
        </Row>
        {summaryIDToGET && auth?.user ? (
          <SummaryResultModal
            key={`${summaryIDToGET}_${summaryResultModalVisible}`}
            isAdminRoute={auth?.user?.is_superuser || false}
          />
        ) : null}
      </Container>
    </React.Fragment>
  ) : (
    <ScreenSpinner />
  );
}
