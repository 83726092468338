import React from "react";
import {
  mdiAccountCircleOutline,
  mdiAccountSearch,
  mdiChevronDown,
  mdiCrownCircleOutline,
  mdiPlaylistEdit,
  mdiPower,
  mdiWrenchOutline,
} from "@mdi/js";
import Icon from "@mdi/react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { useAuth } from "../../Services/auth";
// import devDebug from "../Helpers/debug";
import { useComponentsStore } from "../../Stores/componentsStore";
import { useTenderStrategyStore } from "../../Stores/tenderStrategyStore";
import { useStrategies } from "../../Services/strategies/strategyService";

export default function ProfileDropdown({
  setMenu,
  menu,
  setShowUnsavedPopup,
  showUnsavedPopup,
}) {
  const { t } = useTranslation();
  const strategyService = useStrategies();
  const [isDark] = useComponentsStore((state) => [state.isDark]);

  let auth = useAuth();
  return (
    <Dropdown
      isOpen={menu}
      toggle={() => setMenu(!menu)}
      className="d-inline-block tc-fullscreen-margin-compensator cursor-pointer"
    >
      <DropdownToggle className="" id="page-header-user-dropdown" tag="div">
        {auth.isSuperUser ? (
          <Icon
            path={mdiCrownCircleOutline}
            className=""
            title={t("Admin")}
            size={1}
            color={isDark ? "#8395cb" : "#292ba2"}
          />
        ) : (
          <Icon
            path={mdiAccountCircleOutline}
            className=""
            title={t("Admin")}
            size={1}
            color={isDark ? "#8395cb" : "#292ba2"}
          />
        )}
        <Icon
          path={mdiChevronDown}
          className="d-xl-inline-block profile_menu_chevron"
          title={t("Profile Dropdown Menu")}
          size={0.7}
        />
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-end">
        <div className="dropdown-item tc-text-align-right pe-none">
          {auth?.user?.first_name}
        </div>
        <DropdownItem divider />
        {auth.isSuperUser ? (
          <React.Fragment>
            <NavLink
              onClick={() => setMenu(!menu)}
              to="/admin/edit_tenders"
              className="dropdown-item"
            >
              <Icon
                path={mdiPlaylistEdit}
                className="me-2 d-xl-inline-block profile_menu_settings_btn"
                title={t("Settings")}
                size={0.7}
              />
              {t("Edit Tenders")}
            </NavLink>
            <NavLink
              onClick={() => setMenu(!menu)}
              to="/admin/vademecum"
              className="dropdown-item"
            >
              <Icon
                path={mdiPlaylistEdit}
                className="me-2 d-xl-inline-block profile_menu_settings_btn"
                title={t("Vademecum")}
                size={0.7}
              />
              {t("Vademecum")}
            </NavLink>
            <NavLink
              onClick={() => setMenu(!menu)}
              to="/admin/list_users"
              className="dropdown-item"
            >
              <Icon
                path={mdiAccountSearch}
                className="me-2 d-xl-inline-block profile_menu_settings_btn"
                title={t("Settings")}
                size={0.7}
              />
              {t("List Users")}
            </NavLink>
          </React.Fragment>
        ) : null}
        <NavLink
          onClick={() => setMenu(!menu)}
          to="/user_preferences"
          className="dropdown-item"
        >
          <Icon
            path={mdiWrenchOutline}
            className="me-2 d-xl-inline-block profile_menu_settings_btn"
            title={t("Settings")}
            size={0.7}
          />
          {t("Settings")}
        </NavLink>
        <div
          className="cursor-pointer dropdown-item profile_menu_logout_btn"
          onClick={() => {
            if (useTenderStrategyStore.getState().unsavedWarning === 1) {
              setShowUnsavedPopup(!showUnsavedPopup);
            } else {
              auth.signout(() => {
                strategyService.setFirstRun(true);
                strategyService.setStrategiesStatus("loading");
                window.location.reload();
              });
            }
          }}
        >
          <Icon
            path={mdiPower}
            className="me-2 d-xl-inline-block cursor-pointer"
            title={t("Logout")}
            size={0.7}
            color="#f46a69"
          />
          <span>{t("Logout")}</span>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
}
