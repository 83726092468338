import React, { useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import i18n from "../../i18n";
import spanishFlag from "../../Assets/Images/Flags/spain.jpg";
import germanFlag from "../../Assets/Images/Flags/germany.jpg";
import italianFlag from "../../Assets/Images/Flags/italy.jpg";
import frenchFlag from "../../Assets/Images/Flags/france.jpg";
import ukFlag from "../../Assets/Images/Flags/uk.jpg";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../Services/auth";

const languages = {
  es: {
    label: "Spanish",
    flag: spanishFlag,
  },
  de: {
    label: "German",
    flag: germanFlag,
  },
  it: {
    label: "Italian",
    flag: italianFlag,
  },
  fr: {
    label: "French",
    flag: frenchFlag,
  },
  en: {
    label: "English",
    flag: ukFlag,
  },
};

export default function LanguageDropdown() {
  const { t } = useTranslation();

  const auth = useAuth();
  const apiService = auth.apiService;

  function returnLang(lang) {
    if (typeof lang === "string") {
      if (lang.includes("es")) {
        localStorage.setItem("i18nextLng", "es");
        return "es";
      } else if (lang.includes("de")) {
        localStorage.setItem("i18nextLng", "de");
        return "de";
      } else if (lang.includes("it")) {
        localStorage.setItem("i18nextLng", "it");
        return "it";
      } else if (lang.includes("fr")) {
        localStorage.setItem("i18nextLng", "fr");
        return "fr";
      } else {
        localStorage.setItem("i18nextLng", "en");
        return "en";
      }
    } else {
      return "en";
    }
  }

  const [selectedLang, setSelectedLang] = useState(
    returnLang(localStorage.getItem("i18nextLng")) || "en"
  );
  const [menu, setMenu] = useState(false);

  const patchedUser = (resp) => {
    auth.updateLocalUserDetails(resp);
    auth.show({
      message: t("Language updated successfully!"),
      type: "alert_success",
    });
  };

  const receivedUser = (resp, data) => {
    const controller = new AbortController();
    resp.preferred_language = data.lang;
    delete resp.subscription;

    apiService.axiosPATCH(
      `auth/user/`, //endpoint
      controller.signal, //signal
      patchedUser, //successFn
      auth.newErrorHandler, //error AlerterFn
      "Error updating language", //error message
      null, //errorFn (gets error back)
      { payload: resp, section: "Language Dropdown -> receivedUser" } //data (gets handed back to success fn)
    );
  };

  const changeLanguageAction = (lang) => {
    i18n.changeLanguage(lang);

    setSelectedLang(lang);
    if (auth.getTokenAndFirstTimeFromLocalStorage()) {
      const controller = new AbortController();
      apiService.axiosGET(
        `auth/user/`, //endpoint
        controller.signal, //signal
        receivedUser, //successFn
        auth.newErrorHandler, //error AlerterFn
        "There was an error getting the user", //error message
        null, //errorFn (gets error back)
        { lang: lang, section: "Language Dropdown -> changeLanguageAction" } //data (gets handed back to success fn)
      );
    }
  };

  const toggle = () => {
    setMenu(!menu);
  };

  return (
    <Dropdown
      direction="left"
      isOpen={menu}
      toggle={toggle}
      className="d-inline-block language_dropdown cursor-pointer"
    >
      <DropdownToggle className="" tag="div">
        <img
          src={languages[selectedLang].flag}
          alt={`${selectedLang}.flag Flag Icon`}
          height="16"
        />
      </DropdownToggle>
      <DropdownMenu className="language-switch dropdown-menu-end">
        {Object.keys(languages).map((key) => (
          <DropdownItem
            key={key}
            onClick={() => changeLanguageAction(key)}
            className={`notify-item ${
              selectedLang === key ? "active" : "none"
            }`}
          >
            <img
              src={languages[key].flag}
              alt={`${selectedLang}.flag Flag Icon`}
              className="me-1"
              height="12"
            />
            <span className="align-middle">{languages[key].label}</span>
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
}
