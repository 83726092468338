import React from "react";
import devDebug from "../../Helpers/debug";
import { ErrorBoundary } from "react-error-boundary";
import { MainAppErrorComponent } from "../../Components/errorComponent";
import { useVademecumStore } from "../../Stores/vademecumStore";
import VademecumPage from "./vademecumPage";

export default function VademecumErrorBoundary() {
  const [resetState] = useVademecumStore((state) => [state.resetState]);

  return (
    <ErrorBoundary
      FallbackComponent={MainAppErrorComponent}
      onReset={(details) => {
        devDebug("Vademecum Admin -> error boundary reset: ", details);
        resetState();
      }}
    >
      <VademecumPage />
    </ErrorBoundary>
  );
}
