import React from "react";
import Select from "react-select";
import devDebug from "../../../Helpers/debug";
import { useAuth } from "../../../Services/auth";
import { Input, Spinner } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useViewEditTenderStore } from "../../../Stores/viewEditTender";

export default function UserSelectionComponent({ title, getCPVTemplates }) {
  const auth = useAuth();
  const { t } = useTranslation();

  const apiService = auth.apiService;
  const [
    user,
    setUser,
    usersList,
    setUsersList,
    setSearch,
    userStatus,
    setUserStatus,
  ] = useViewEditTenderStore((state) => [
    state.user,
    state.setUser,
    state.usersList,
    state.setUsersList,
    state.setSearch,
    state.userStatus,
    state.setUserStatus,
  ]);

  function debounce(func, timeout = 700) {
    //devDebug("debounce");
    let timer;
    return (...args) => {
      devDebug(
        "alreadySubmittedModal -> debounce -> submitting, with args: ",
        ...args
      );
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  }
  const searchChange = debounce((input) => applySearchTerm(input));

  function applySearchTerm(input) {
    devDebug(
      "alreadySubmittedModal -> debounce -> applying search term: ",
      input
    );
    setSearch(input);
    getUsers();
  }

  const getUsersSuccess = (resp) => {
    let users = [];
    for (let usr of resp.results) {
      if (usr.email) {
        users.push({ value: usr.id, label: usr.email });
      }
    }
    setUsersList(users);
    setUserStatus("loaded");
  };
  const getUsersError = () => {
    setUserStatus("error");
  };

  const getUsers = () => {
    setUserStatus("refreshing");
    const search = useViewEditTenderStore.getState().search;
    const controller = new AbortController();
    let isSubscribed = true;
    if (isSubscribed) {
      apiService.axiosGET(
        `UAadmin/users/?page_size=max&page=1&search=${search}`, //endpoint
        controller.signal, //signal
        getUsersSuccess, //successFn
        auth.newErrorHandler, //error AlerterFn
        "Error retrieving user list", //error message
        getUsersError, //errorFn (gets error back)
        { section: "User selection modal -> getUsers" } //data (gets handed back to success fn)
      );
    }
    return () => {
      controller.abort();
      isSubscribed = false;
    };
  };

  React.useEffect(() => {
    getUsers();
  }, []);

  React.useEffect(() => {
    if (!user.value) {
      setUser({ value: auth.user.id, label: auth.user.email });
    }
  }, [user]);

  return (
    <React.Fragment>
      <div className="tc-text-muted">
        <div className="mt-1 mb-4 fw-bold">{title}:</div>
        <div className="px-1 pb-1">
          <Input
            type="text"
            onChange={(e) => searchChange(e.target.value)}
            placeholder={t("Search user by email")}
          />
        </div>
        {userStatus === "refreshing" ? <Spinner color="secondary" /> : null}
      </div>
      <div className=" d-flex flex-column tc-flex-gap-10px p-1 mt-1 mb-5">
        <Select
          defaultValue={
            user ? user : { value: auth.user.id, label: auth.user.email }
          }
          name="users_selection"
          className="me-2 react-select-container"
          classNamePrefix="react-select"
          key={`userselection-${JSON.stringify(usersList)}`}
          options={usersList} // Options to display in the dropdown
          onChange={(e) => {
            setUser(e);
            if (getCPVTemplates) {
              getCPVTemplates();
            }
          }}
        />
      </div>
      {userStatus === "error" ? (
        <div className="tc-formerror">
          {t("There was an error loading users")}
        </div>
      ) : null}
    </React.Fragment>
  );
}
