import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import Header from "./header";
import Footer from "./footer";
import { SidebarContext } from "./sidebarContext";
import Alert from "../Components/alerter";
import { CookiePopup } from "./cookiePopup";
import HomeMultiLanguageVideoPopup from "./modals/homeMultiLanguageVideoPopup";
import UpgradeModal from "./modals/upgradeModal";
import UnsavedPopup from "./modals/unsavedPopup";
import HelpVideoModal from "../Components/modals/helpModal";
import Navigation from "./nav/navigation";

const SidebarProvider = (props) => {
  const [menuOpenState, setMenuOpenState] = React.useState(false);
  return (
    <SidebarContext.Provider
      value={{
        setMenuOpenState,
        isMenuOpen: menuOpenState,
        toggleMenu: (val) => setMenuOpenState(val),
        stateChangeHandler: (newState) => setMenuOpenState(newState.isOpen),
      }}
    >
      {props.children}
    </SidebarContext.Provider>
  );
};

export default function Layout() {
  const location = useLocation();

  return (
    <React.Fragment>
      <CookiePopup />
      <UpgradeModal />
      <UnsavedPopup />
      <HelpVideoModal />
      <HomeMultiLanguageVideoPopup />
      <SidebarProvider>
        <div
          className="min-vh-100 d-flex flex-column
                justify-content-between"
        >
          <div className="body-wrapper">
            <Header location={location} />
            <Navigation sidebar={true} />
            <Outlet />
          </div>
          <Footer />
        </div>
        <Alert />
      </SidebarProvider>
    </React.Fragment>
  );
}
