import React from "react";
import { Spinner } from "reactstrap";
import { useTranslation } from "react-i18next";

export default function ScreenSpinner({ copy }) {
  const { t } = useTranslation();
  return (
    <div className="th-screen-filling-spinner-container">
      <Spinner color="primary" />
      <div className="ms-2 tc-text-muted tc-max-width-70vw">{`${
        copy ? t(copy) : t("Initialising Section...")
      }`}</div>
    </div>
  );
}
