import React from "react";
import { SidebarContext } from "./sidebarContext";
import { useAuth } from "../Services/auth";
import Icon from "@mdi/react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { Link, NavLink } from "react-router-dom";
import TendersClubLogo from "../Components/tendersClubLogo";
import { mdiMenu, mdiFullscreen } from "@mdi/js";
import { useComponentsStore } from "../Stores/componentsStore";
import devDebug from "../Helpers/debug";
import Navigation from "./nav/navigation";
import LanguageDropdown from "./nav/languageDropdown";
import DarkmodeToggle from "./nav/darkModeToggle";
import ProfileDropdown from "./nav/profileDropdown";
import AwardsSearchDropdown from "./nav/awardsSearchDropdown";

export default function Header({ location }) {
  const [setShowUnsavedPopup, showUnsavedPopup, setAwardsDropDownOpen, isDark] =
    useComponentsStore((state) => [
      state.setShowUnsavedPopup,
      state.showUnsavedPopup,
      state.setAwardsDropDownOpen,
      state.isDark,
    ]);
  const [menu, setMenu] = React.useState(false);
  let auth = useAuth();
  let authenticated = false;
  if (auth.user) {
    authenticated = true;
  }
  const subscription = auth?.user?.subscription;
  let daysLeft = 0;
  if (subscription) {
    daysLeft = dayjs(subscription.subscription_period_end).diff(dayjs(), "day");
  }
  const ctx = React.useContext(SidebarContext);

  const specialToggleMenu = () => {
    setAwardsDropDownOpen(false);
    ctx.toggleMenu(true);
    let isActive = false;
    if (
      typeof document?.getElementsByClassName(
        "mobile-sidebar-nested-dropdown"
      )?.[0] !== "undefined"
    ) {
      for (let item of document
        ?.getElementsByClassName("mobile-sidebar-nested-dropdown")?.[0]
        ?.getElementsByClassName("nested-mobile-menu-item")) {
        if (item.classList.contains("active")) {
          isActive = true;
        }
      }
      if (isActive) {
        window.setTimeout(() => {
          devDebug("setting awards dropdown to open");
          setAwardsDropDownOpen(true);
        }, 400);
      }
    }
  };

  const { t } = useTranslation();
  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  return (
    <header
      id="page-topbar"
      className={`${
        !auth.user && location.pathname === "/dashboard"
          ? "page-topbar-transparent"
          : ""
      } d-flex align-items-center justify-content-between  `}
    >
      <div className="d-flex flex-column w-full ">
        <div className="d-flex p-1 p-md-2 justify-content-between">
          <NavLink to="/" className="tc-horizontal-nav-logo-width">
            {!auth.user && location.pathname === "/dashboard" ? (
              <TendersClubLogo size="normal" colour={"light"} />
            ) : (
              <TendersClubLogo
                size="normal"
                colour={isDark ? "light" : "dark"}
              />
            )}
          </NavLink>
          <Navigation sidebar={false} />
          <div className="me-auto tc-horizontal-nav-item-colours hide-horizontal-nav">
            {authenticated ? (
              <AwardsSearchDropdown sidebar={false} ctx={ctx} />
            ) : null}
          </div>
          <div className="d-flex align-items-center justify-content-between tc-nav-flex-gap">
            <DarkmodeToggle />
            <LanguageDropdown />
            <Icon
              onClick={() => {
                toggleFullscreen();
              }}
              path={mdiFullscreen}
              className="tc-full-screen-btn d-xl-inline-block cursor-pointer tc-fullscreen-margin-compensator"
              title={t("Toggle Full Screen")}
              size={1}
              color={isDark ? "#f6f6f6" : "#555b6d"}
            />
            {authenticated ? (
              <ProfileDropdown
                auth={auth}
                isDark={isDark}
                setMenu={setMenu}
                menu={menu}
                setShowUnsavedPopup={setShowUnsavedPopup}
                showUnsavedPopup={showUnsavedPopup}
              />
            ) : null}
            <Icon
              onClick={() => specialToggleMenu()}
              path={mdiMenu}
              className="cursor-pointer tc-toggle-sidebar-btn"
              title={t("Menu")}
              size={1.6}
              color={isDark ? "#f6f6f6" : "#555b6d"}
            />
          </div>
        </div>

        {subscription?.status === "trialing" ? (
          <div className="trial_notification">
            {daysLeft > 0
              ? t("This is a trial account. Days Left: ") + daysLeft + ". "
              : t("Your trial has expired")}
            <Link to="/pricing">{t("Subscribe now")}</Link>
          </div>
        ) : null}
      </div>
    </header>
  );
}
