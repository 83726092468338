import React from "react";
import { slide as Menu } from "react-burger-menu";
import TendersClubLogo from "../../Components/tendersClubLogo";
import { useAuth } from "../../Services/auth";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { SidebarContext } from "../sidebarContext";
import AwardsSearchDropdown from "./awardsSearchDropdown";
import TendersDropdown from "./tendersDropdown";

export default function Navigation({ sidebar }) {
  const auth = useAuth();
  const ctx = React.useContext(SidebarContext);
  let authenticated = false;
  if (auth.user) {
    authenticated = true;
  }

  return sidebar ? (
    <Menu
      right
      customBurgerIcon={false}
      isOpen={ctx.isMenuOpen}
      onStateChange={(state) => ctx.stateChangeHandler(state)}
      width={320}
    >
      <div className="d-flex flex-column tc-flex-gap-10px">
        <NavLinks sidebar={sidebar} ctx={ctx} authenticated={authenticated} auth={auth} />
        {authenticated ? <AwardsSearchDropdown sidebar={sidebar} ctx={ctx} /> : null}
      </div>
    </Menu>
  ) : (
    <div className={`tc-horizontal-nav ${authenticated ? "authenticated" : "w-full"}`}>
      <NavLinks sidebar={sidebar} ctx={ctx} authenticated={authenticated} auth={auth} />
    </div>
  );
}

const NavLinks = ({ sidebar, ctx, authenticated, auth }) => {
  const { t } = useTranslation();

  const menuActions = () => {
    ctx.toggleMenu(false);
  };

  return (
    <React.Fragment>
      {sidebar ? (
        <NavLink onClick={() => ctx.toggleMenu(false)} key={Math.random()} to="/" className="logo logo-light">
          <TendersClubLogo size="normal" colour="light" />
        </NavLink>
      ) : null}
      <NavLink
        onClick={() => menuActions()}
        key={Math.random()}
        to="/pricing"
        className={`menu-item ${sidebar ? "menu-item-sidebar" : ""}`}
      >
        {t("Pricing")}
      </NavLink>
      {authenticated ? (
        <TendersDropdown sidebar={sidebar} ctx={ctx} />
      ) : (
        <NavLink
          onClick={() => menuActions()}
          key={Math.random()}
          to="/search"
          className={`menu-item ${sidebar ? "menu-item-sidebar" : ""}`}
        >
          {t("Search Tenders")}
        </NavLink>
      )}
      {/* <NavLink
        onClick={() => menuActions()}
        key={Math.random()}
        to="/search"
        className={`menu-item ${sidebar ? "menu-item-sidebar" : ""}`}
      >
        {t("Search Tenders")}
      </NavLink>
      <NavLink
        onClick={() => menuActions()}
        key={Math.random()}
        to="/my_tenders"
        className={`menu-item ${sidebar ? "menu-item-sidebar" : ""}`}
      >
        {t("My Tenders")}
      </NavLink> */}
      <NavLink
        onClick={() => menuActions()}
        key={Math.random()}
        to="/contact"
        className={`menu-item ${sidebar ? "menu-item-sidebar" : ""}`}
      >
        {t("Contact")}
      </NavLink>
      {authenticated ? null : (
        <NavLink
          onClick={() => menuActions()}
          key={Math.random()}
          to="/login"
          className={`menu-item ${!sidebar ? " ms-auto" : " menu-item-sidebar"}`}
        >
          {t("Login")}
        </NavLink>
      )}
      {authenticated ? null : (
        <NavLink
          onClick={() => menuActions()}
          key={Math.random()}
          to="/register"
          className={`menu-item ${sidebar ? "menu-item-sidebar" : ""}`}
        >
          {t("Register")}
        </NavLink>
      )}
      {authenticated && sidebar ? (
        <NavLink
          onClick={() => menuActions()}
          key={Math.random()}
          to="/user_preferences"
          className={`menu-item ${sidebar ? "menu-item-sidebar" : ""}`}
        >
          {t("Settings")}
        </NavLink>
      ) : null}
      {authenticated ? (
        <NavLink
          onClick={() => menuActions()}
          key={Math.random()}
          to="/tender_strategy"
          className={`menu-item ${sidebar ? "menu-item-sidebar" : ""}`}
        >
          {t("Tender Strategy")}
        </NavLink>
      ) : null}
      {auth.isSuperUser && sidebar ? (
        <NavLink
          onClick={() => menuActions()}
          key={Math.random()}
          to="/admin/edit_tenders"
          className={`menu-item ${sidebar ? "menu-item-sidebar" : ""}`}
        >
          {t("Edit Tenders")}
        </NavLink>
      ) : null}
      {auth.isSuperUser && sidebar ? (
        <NavLink
          onClick={() => menuActions()}
          key={Math.random()}
          to="/admin/vademecum"
          className={`menu-item ${sidebar ? "menu-item-sidebar" : ""}`}
        >
          {t("Vademecum")}
        </NavLink>
      ) : null}
      {auth.isSuperUser && sidebar ? (
        <NavLink
          onClick={() => menuActions()}
          key={Math.random()}
          to="/admin/list_users"
          className={`menu-item ${sidebar ? "menu-item-sidebar" : ""}`}
        >
          {t("List Users")}
        </NavLink>
      ) : null}
    </React.Fragment>
  );
};
